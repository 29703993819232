import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthApi } from './api/auth/auth.api';
import { AuthInterceptor } from './auth-interceptor/auth.interceptor.class';
import { ROUTES } from './auth-routing.module';
import { AuthenticatePage } from './authenticate/authenticate.page';
import { OAuthService } from './shared/oauth/oauth.service';
import { UserService } from './shared/user/user.service';

/**
 * @deprecated use {@see provideTtAuth} function
 */
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(ROUTES), AuthenticatePage],
  exports: [AuthenticatePage],
  providers: [
    AuthApi,
    UserService,
    OAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class BaseAuthModule {}
