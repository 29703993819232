import { sortDefined } from './sort-defined.function';
import { SortDirection } from './sort-direction.type';

export function sortString(
  a: string | undefined,
  b: string | undefined,
  direction: SortDirection = 'asc',
): number {
  const sortDefinedResult: number = sortDefined(a, b, direction);
  if (sortDefinedResult !== 0) {
    return sortDefinedResult;
  }

  return a ? (a ?? '').localeCompare(b ?? '') : 1 * (direction === 'asc' ? 1 : -1);
}
