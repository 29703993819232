import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthApi } from './api/auth/auth.api';
import { AuthApiMock } from './api/auth/auth.api.mock';
import { AuthInterceptor } from './auth-interceptor/auth.interceptor.class';
import { ROUTES } from './auth-routing.module';
import { AuthConfig } from './auth.config';
import { BaseAuthModule } from './base-auth.module';
import { InAppBrowserWebService } from './shared/in-app-browser/in-app-browser-web.service';
import { InAppBrowserService } from './shared/in-app-browser/in-app-browser.service';
import { OAuthService } from './shared/oauth/oauth.service';
import { UserInterface } from './shared/user/user.interface';
import { UserService } from './shared/user/user.service';
import { UserServiceMock } from './shared/user/user.service.mock';

/**
 * @deprecated use provider instead
 */
@NgModule({
  imports: [BaseAuthModule, RouterModule.forChild(ROUTES)],
  exports: [BaseAuthModule],
  providers: [
    { provide: AuthApi, useClass: AuthApiMock },
    { provide: UserService, useClass: UserServiceMock },
    OAuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthTestingModule {
  public static forRoot<T extends UserInterface>(
    options: AuthConfig<T>,
  ): ModuleWithProviders<AuthTestingModule> {
    options = { ...new AuthConfig(), ...options };

    return {
      ngModule: AuthTestingModule,
      providers: [
        { provide: AuthConfig, useValue: options },
        { provide: InAppBrowserService, useClass: InAppBrowserWebService },
        { provide: AuthApi, useClass: AuthApiMock },
        { provide: UserService, useClass: UserServiceMock },
        OAuthService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true,
        },
      ],
    };
  }
}
