import { compareAsc, compareDesc } from 'date-fns';
import { sortDefined } from './sort-defined.function';
import { SortDirection } from './sort-direction.type';

export function sortDate(
  a: Date | string | undefined,
  b: Date | string | undefined,
  direction: SortDirection = 'asc',
): number {
  const sortDefinedResult: number = sortDefined(a, b, direction);
  if (sortDefinedResult !== 0) {
    return sortDefinedResult;
  }

  if (!a || !b) {
    return 0;
  }

  return direction === 'asc' ? compareAsc(a, b) : compareDesc(a, b);
}
