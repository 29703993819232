import {
  animate,
  animation,
  AnimationReferenceMetadata,
  AnimationTriggerMetadata,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { M3Duration, M3Easing } from './m3-motion.enum';

export const enterFromTopEnterAnimation: AnimationReferenceMetadata = animation([
  style({ gridTemplateRows: '0fr' }),
  animate('{{ duration }} {{ easing }}', style({ gridTemplateRows: '1fr' })),
]);

export const enterFromTopEnterLeaveAnimation: AnimationReferenceMetadata = animation([
  style({ gridTemplateRows: '1fr' }),
  animate('{{ duration }} {{ easing }}', style({ gridTemplateRows: '0fr' })),
]);

//eslint-disable-next-line max-lines-per-function
export function createEnterFromTopAnimation(config?: {
  key?: string;
  enterDuration?: M3Duration;
  leaveDuration?: M3Duration;
  enterEasing?: M3Easing;
  leaveEasing?: M3Easing;
}): AnimationTriggerMetadata {
  const mergedConfig: Required<Parameters<typeof createEnterFromTopAnimation>[0]> = {
    key: 'enterFromTop',
    enterDuration: M3Duration.LONG,
    leaveDuration: M3Duration.EXTRA_LONG,
    enterEasing: M3Easing.EMPHASIZED_DECELERATE,
    leaveEasing: M3Easing.EMPHASIZED_ACCELERATE,
    ...config,
  };
  return trigger(mergedConfig.key, [
    transition(
      ':enter',
      useAnimation(enterFromTopEnterAnimation, {
        params: {
          duration: mergedConfig.enterDuration,
          easing: mergedConfig.enterEasing,
        },
      }),
    ),
    transition(
      ':leave',
      useAnimation(enterFromTopEnterLeaveAnimation, {
        params: {
          duration: mergedConfig.leaveDuration,
          easing: mergedConfig.leaveEasing,
        },
      }),
    ),
  ]);
}
