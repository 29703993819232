import { DefaultUrlSerializer, Params, UrlTree } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { Token } from '../../models/token.model';

export class OAuthServiceMock {
  public initiateAuthCodePKCE: () => Promise<void> = jest.fn(() => Promise.resolve());

  public exchangeAuthCodePKCE: (code: string) => Observable<void> = jest.fn(() => {
    return EMPTY;
  });

  public hasValidAccessToken: () => Promise<boolean> = jest.fn(() => Promise.resolve(true));

  public refreshAccessToken: () => Promise<boolean> = jest.fn(() => Promise.resolve(true));

  public clearAuthenticationTokens: (clearOriginUrl: boolean) => Promise<void> = jest.fn(() =>
    Promise.resolve(),
  );

  public redirectToLoginPage: () => Promise<boolean> = jest.fn(() => Promise.resolve(true));

  public redirectAfterLogin: (options: {
    forceRedirectHome?: boolean;
    queryParams?: Params;
  }) => Promise<boolean> = jest.fn(() => Promise.resolve(true));

  public getLoginUrlTree: () => UrlTree = jest.fn(() => new DefaultUrlSerializer().parse('/login'));

  public getRedirectAfterLoginUrlTree: (options: {
    forceRedirectHome?: boolean;
    queryParams?: Params;
  }) => Promise<UrlTree> = jest.fn(() =>
    Promise.resolve(new DefaultUrlSerializer().parse('/dashboard')),
  );

  public storeAccessToken: (token: Token) => Promise<void> = jest.fn(() => Promise.resolve());
}
