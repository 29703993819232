import { Pipe, PipeTransform, inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  getTextDimensions,
  normalizeSearchString,
  normalizeTextString,
} from '../../functions/text-helpers/text-helper';

export interface TextMetaData {
  textFont: string;
  textContainerSize: ResizeObserverEntry;
}

interface TextPiecesWithIndex {
  index: number;
  text: string;
  isHighlight: boolean;
}

interface SizedText {
  text: string;
  size: number;
}

enum Direction {
  START = 'START',
  END = 'END',
  MIDDLE = 'MIDDLE',
}

@Pipe({
  name: 'highlightSearchText',
  standalone: true,
})
export class HighlightSearchPipe implements PipeTransform {
  protected readonly sanitizer = inject(DomSanitizer);

  /**
   * CSS class which is assigned to the highlighting span.
   */
  private highlightClass: string = 'highlight';

  /**
   * Metadata about the text container and its font
   */
  private textMetaData: TextMetaData | undefined;

  /**
   * The width in pixels of the text box.
   */
  private containerWidth!: number;

  /**
   * They width of the text in pixels ` ...` or `... `.
   */
  private dotSize!: number;

  /**
   * @inheritDoc
   * An interface that is implemented by pipes in order to perform a transformation.
   * Angular invokes the `transform` method with the value of a binding
   * as the first argument, and any parameters as the second argument in list form.
   *
   * @usageNotes
   *
   * In the following example, `RepeatPipe` repeats a given value a given number of times.
   *
   * ```ts
   * import {Pipe, PipeTransform} from '@angular/core';
   *
   * @Pipe({name: 'repeat'})
   * export class RepeatPipe implements PipeTransform {
   *   transform(value: any, times: number) {
   *     return value.repeat(times);
   *   }
   * }
   * ```
   *
   * Invoking `{{ 'ok' | repeat:3 }}` in a template produces `okokok`.
   *
   * @publicApi
   */
  public transform(
    value: unknown,
    search: string,
    highlightClass?: string,
    metaData?: TextMetaData,
  ): SafeHtml | unknown {
    if (highlightClass) {
      this.highlightClass = highlightClass;
    }
    if (metaData) {
      this.textMetaData = metaData;
      this.containerWidth = this.textMetaData.textContainerSize.contentRect.width;
      this.dotSize = this.getTextSize(' ...');
    }
    if (!search || typeof search !== 'string' || !value || typeof value !== 'string') {
      return value;
    }

    const searchString: string = normalizeSearchString(search);
    const textString: string = normalizeTextString(value);

    let transformedString: string = this.createTransformedString(searchString, textString, value);

    return this.sanitizer.bypassSecurityTrustHtml(transformedString);
  }

  /**
   * Split the search into segment and searches the text for each segment keeping
   * track of the order in which the segments are given.
   *
   * It wil return the original string with a span (containing the highlight class)
   * around each found segment.
   *
   * If beginWithFirstHighlight is set it will strip all text of the original string
   * until the first highlight.
   */
  //eslint-disable-next-line max-lines-per-function
  private createTransformedString(
    searchString: string,
    textString: string,
    originalString: string,
  ): string {
    let searchSegments: string[] = this.splitSearchStringIntoSegments(searchString);
    let leftOvers: { real: string; formatted: string } = {
      real: originalString,
      formatted: textString,
    };
    let textPieces: TextPiecesWithIndex[] = [];

    let startIndex: number = 0;
    for (let segment of searchSegments) {
      const index: number = leftOvers.formatted.search(this.createSegmentRegex(segment));
      const length: number = segment.length;
      if (index === -1) {
        // not all search segments are found. So the text doesn't match the search query
        return originalString;
      }

      const beforeHighlight: string = leftOvers.real.slice(0, index);
      const textHighlight: string = leftOvers.real.slice(index, index + length);
      const totalIndex: number = startIndex + index;
      textPieces.push({
        index: totalIndex - beforeHighlight.length,
        text: beforeHighlight,
        isHighlight: false,
      });
      textPieces.push({ index: totalIndex, text: textHighlight, isHighlight: true });

      leftOvers = {
        real: leftOvers.real.slice(index + length),
        formatted: leftOvers.formatted.slice(index + length),
      };
      startIndex = index + length;
    }

    if (textPieces.length === 0) {
      return originalString;
    }

    const lastSegment: TextPiecesWithIndex = textPieces[textPieces.length - 1];
    textPieces.push({
      index: lastSegment.index + lastSegment.text.length,
      text: leftOvers.real,
      isHighlight: false,
    });

    return this.createSearchResultString(originalString, textPieces);
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Splits the given string into segments using whitespace a separator.
   * After the split it filters all empty segments from the array.
   * @param searchString
   * @private
   */
  private splitSearchStringIntoSegments(searchString: string): string[] {
    return searchString.split(' ').filter((segment) => segment && segment !== '');
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Returns a search regex based on the given segment string. Escaping all
   * special characters.
   */
  private createSegmentRegex(segment: string): RegExp {
    return new RegExp(segment.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'gi');
  }

  /**
   * Returns the segment wrapped in the highlight span.
   */
  private highlightSegment(segment: string): string {
    return `<span class="${this.highlightClass}">${segment}</span>`;
  }

  /**
   * The method wil returns the end result string. It clues the text pieces together
   * but before it does that it check if the text fit into view if not it wil do
   * the following
   *
   * - first is check if the highlighted text does fit on the screen. If so it still
   *     returns the full string which will be cut off through css styling.
   * - TODO if that doesn't fit it will check if the first and last highlight fit's in view
   *     if not it stripped words from the start and middel pieces until it does fit.
   * - if it does fit it will only strip word from the start of the string until it fits.
   */
  private createSearchResultString(text: string, textPieces: TextPiecesWithIndex[]): string {
    let pieces: TextPiecesWithIndex[] = textPieces;

    if (!this.textMetaData) {
      return this.joinTextPiecesToString(pieces);
    }

    // All highlight segment fit on the screen so we can use the original string.
    if (
      this.getTextSize(text.slice(0, pieces[pieces.length - 1].index - 1)) <
      this.containerWidth - this.dotSize
    ) {
      return this.joinTextPiecesToString(pieces);
    }

    const firstHighlight: TextPiecesWithIndex = pieces[1];
    const lastHighlight: TextPiecesWithIndex = pieces[pieces.length - 2];

    const sizeOfTextFullHighlight: number = this.getTextSize(
      text.slice(firstHighlight.index, lastHighlight.index + lastHighlight.text.length),
    );

    if (sizeOfTextFullHighlight > this.containerWidth) {
      pieces = this.stripFrontAndMiddleText(pieces, sizeOfTextFullHighlight);
    } else {
      pieces = this.stripOnlyFromFrontText(pieces, sizeOfTextFullHighlight);
    }

    return this.joinTextPiecesToString(pieces);
  }

  /**
   * Strips word from the first text Piece until they full text fits in the container width.
   */
  private stripOnlyFromFrontText(
    textPieces: TextPiecesWithIndex[],
    sizeOfTextFullHighlight: number,
  ): TextPiecesWithIndex[] {
    // de eerst highlight past er al niet op.
    const newStartString: string = this.stripWordsUntilFit(
      textPieces[0].text,
      this.containerWidth - this.dotSize * 2 - sizeOfTextFullHighlight,
      Direction.START,
    );
    textPieces[0] = { ...textPieces[0], text: newStartString };
    return textPieces;
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Strips word from the first text Piece and the middle text pieces until they
   * full text fits in the container width.
   */
  private stripFrontAndMiddleText(
    textPieces: TextPiecesWithIndex[],
    _sizeOfTextFullHighlight: number,
  ): TextPiecesWithIndex[] {
    // todo split start, middle until fit.
    return textPieces;
  }

  /**
   * Glues the text pieces back together highlighting the highlights where needed.
   */
  private joinTextPiecesToString(textPieces: TextPiecesWithIndex[]): string {
    return textPieces
      .map((textPiece) => {
        return textPiece.isHighlight ? this.highlightSegment(textPiece.text) : textPiece.text;
      })
      .join('');
  }

  /**
   * Strips words from the give text until it fits in the given maxSize.
   * Depending on the case it will start stripping word from:
   *  - START: start with the first word in the sentence and strips from left to right
   *  - End: start with the last word in a sentence and strips from right to left.
   *  - Middle: start in the center stripping round-robin first from the left than the right etc.
   */
  private stripWordsUntilFit(text: string, maxSize: number, direction: Direction): string {
    // they dot are added at for hand so that the are included in the calculation
    let words: SizedText[] = text
      .split(' ')
      .filter((word) => word.length > 0)
      .map((word, index, array) => {
        /* istanbul ignore next */
        if (direction === Direction.END && array.length - 1 === index) {
          return { text: word, size: this.getTextSize(word) };
        }
        return { text: `${word} `, size: this.getTextSize(`${word} `) };
      });

    const strippedString: SizedText = this.setWordOrder(words, direction).reduce(
      (accumulator, current, index) => {
        const size: number = accumulator.size + current.size;
        if (size <= maxSize) {
          switch (direction) {
            case Direction.START:
              return { text: `${current.text}${accumulator.text}`, size: size };
            /* istanbul ignore next */
            case Direction.MIDDLE: // Not used since strip in middle isn't implemented yet.
              if (index === 0) {
                return { text: `${current.text} ... ${accumulator.text}`, size: size };
              }
              if (index % 2 === 0) {
                return { text: `${current.text} ${accumulator.text}`, size: size };
              }
              return { text: `${accumulator.text} ${current.text}`, size: size };
            /* istanbul ignore next */
            default: // Not used since strip at end middle isn't implemented yet.
              return { text: `${accumulator.text} ${current.text}`, size: size };
          }
        }
        return { text: accumulator.text, size: size };
      },
    );
    return direction === Direction.START ? `... ${strippedString.text}` : `${strippedString.text}`;
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Set the array of given word in the order in which they get strip. {@see HighlightSearchPipe.stripWordsUntilFit()}
   */
  private setWordOrder(words: SizedText[], direction: Direction): SizedText[] {
    switch (direction) {
      case Direction.START:
        return words.reverse();
      /* istanbul ignore next */
      case Direction.MIDDLE: {
        // not used since strip in middel isn't implemented yet
        const middleIndex: number = Math.floor(words.length / 2);
        const startWords: SizedText[] = words.slice(0, middleIndex).reverse();
        const endWords: SizedText[] = words.slice(middleIndex + 1);

        let sortedWords: SizedText[] = [];

        for (let [index, word] of startWords.entries()) {
          sortedWords.push(word);
          sortedWords.push(endWords[index]);
        }
        return sortedWords;
      }
      /* istanbul ignore next */
      default: // Not used since strip at end isn't implemented yet.
        return words;
    }
  }

  /**
   * Calculate the pixel size of the given text.
   */
  private getTextSize(text: string): number {
    return getTextDimensions(text, this.textMetaData?.textFont).width;
  }
}
