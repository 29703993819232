/*
 * Public API Surface of ionic-oauth
 */
export * from './lib/auth-routing.module';
export * from './lib/auth.config';
export * from './lib/base-auth.module';

export * from './lib/shared/user/user.service';
export * from './lib/shared/user/user.service.mock';
export * from './lib/shared/user/user.interface';

export * from './lib/models/lyceo-user.model';
export * from './lib/models/token.model';

export * from './lib/shared/oauth/oauth.service';
export * from './lib/shared/oauth/oauth.service.mock';

/**
 * @deprecated use {@see LyceoUser} This model is fully backward compatible with the interface
 * but also exposes the same property using the correct naming-convention and more.
 */
export { AuthGetProfileResponse } from './lib/api/auth/auth.response';

/**
 * @deprecated use {@see Token } or better not at all
 */
export { AuthGetTokenResponse } from './lib/api/auth/auth.response';

export * from './lib/shared/errors/invalid-role.error';
export * from './lib/shared/errors/invalid-token.error';
export * from './lib/shared/errors/profile-call-fails.error';

export * from './lib/oauth-guard/oauth.guard';
export * from './lib/auth-interceptor/auth.interceptor.class';

export * from './lib/shared/in-app-browser/in-app-browser.service';
export * from './lib/api/auth/auth.api';
export * from './lib/api/auth/auth.api.mock';
export * from './lib/api/auth/auth.response.mock';
export * from './lib/authenticate/authenticate.page';

export * from './lib/shared/in-app-browser/in-app-browser-web.service';
export * from './lib/auth.module';
export * from './lib/auth-testing.module';

export * from './lib/auth.provider';
export * from './lib/auth-testing.provider';
export * from './lib/pkce/pkce-authentication.guard';
export * from './lib/oauth-guard/oauth-authentication.guard';
export * from './lib/auth-interceptor/auth.interceptor';
