import { Action, createReducer, on } from '@ngrx/store';
import { LocationMemberContract } from '@school-dashboard/person-contracts';
import { LyceoUser } from '@techniek-team/oauth';
import { usersActions } from './users.actions';

export const USERS_FEATURE_KEY = 'users';

export interface UsersState {
  user?: LyceoUser; // which Users record has been selected
  currentLocationMembers: LocationMemberContract[];
  loaded: boolean; // has the Users list been loaded
  loading: boolean; // is the Users busy with loading
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any | null; // last known error (if any)
}

export const initialUsersState: UsersState = {
  currentLocationMembers: [],
  loaded: false,
  loading: false,
};

const reducer = createReducer(
  initialUsersState,
  on(usersActions.initUser, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null,
  })),
  on(usersActions.loadUserSuccess, (state, { user }) => ({
    ...state,
    user: user,
    loaded: true,
    loading: false,
  })),
  on(usersActions.loadUserFailure, (state, { error }) => ({
    ...state,
    error: error,
  })),
);

export function usersReducer(state: UsersState | undefined, action: Action): UsersState {
  return reducer(state, action);
}
