import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function requiredFileExtensionsValidator(fileExtensions: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file: { name: string } = control.value;

    if (!file) {
      return null;
    }

    const parts: string[] = file.name.split('.');
    const extension: string = parts[parts.length - 1];

    if (fileExtensions.find((fileType) => fileType === extension.toLowerCase())) {
      return null;
    }

    return { requiredFileType: { value: control.value } };
  };
}
