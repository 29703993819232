import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { Storage } from '@ionic/storage-angular';
import { firstValueFrom } from 'rxjs';
import { InvalidRoleError } from '../shared/errors/invalid-role.error';
import { InvalidTokenError } from '../shared/errors/invalid-token.error';
import { ProfileCallFailsError } from '../shared/errors/profile-call-fails.error';
import { OAuthService } from '../shared/oauth/oauth.service';
import { UserInterface } from '../shared/user/user.interface';
import { UserService } from '../shared/user/user.service';

/**
 * An angular guard which can be used to check oauth authentication.
 *
 * This guard contains the minimal needed for projects to work. For more extensive
 * work this class can be extended.
 *
 * Important note: be sure to implement the {@see saveOriginUrl} when overloading the
 * canActivate method. Without it the login will always redirect the users to the homeUrl
 * defined in the config.
 *
 * @see https://angular.io/guide/router-tutorial-toh#milestone-5-route-guards
 * @deprecated Guard classes are deprecated use the {@see oauthAuthenticationGuard} or
 * create your own function. All functionaliteit of the OauthGaurd is now moved to the
 * {@see OauthAuthenticator} class. If you extended on this class extend on that class and
 * create our own implementation of {@see oauthAuthenticationGuard}
 */
@Injectable({
  providedIn: 'root',
})
export class OAuthGuard implements CanActivate {
  protected readonly oauthService = inject(OAuthService);

  protected readonly userService = inject(UserService);

  protected readonly toastController = inject(ToastController);

  protected readonly storage = inject(Storage);

  /**
   * Interface that a class can implement to be a guard deciding if a route can be activated.
   * {@see CanActivate} for more information
   *
   * @inheritDoc
   */
  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean | UrlTree> {
    await this.saveOriginUrl(state);
    try {
      const authenticated: boolean = await this.userService.isAuthenticated();

      if (!authenticated) {
        return this.oauthService.getLoginUrlTree();
      }

      const promise: void | Promise<void> = this.checkUserRoles(
        await firstValueFrom(this.userService.currentUser()),
        route,
      );

      if (promise instanceof Promise) {
        await promise;
      }

      return authenticated;
    } catch (error) {
      const isHandled: boolean | UrlTree = await this.handleError(error as Error);
      if (isHandled instanceof UrlTree) {
        return isHandled;
      }

      // If the handleError function didn't handle the error it will return false
      // as a fallback we're going to redirect the user to the login page.
      return this.oauthService.getLoginUrlTree();
    }
  }

  /**
   * This method is executed at the start of the Guard's authentication.
   * It saves the original URL into the local storage, so we can redirect the user
   * back to that URL after authentication.
   */
  protected async saveOriginUrl(state: RouterStateSnapshot): Promise<void> {
    await this.storage.create();
    return this.storage.set('originUrl', state.url);
  }

  /**
   * Optional method which can be implemented when extending this oauth guard.
   * It can be used to check on certain roles and permission of the user.
   *
   * This method should throw an {@see InvalidRoleError } when the user has
   * incorrect roles or insufficient permissions.
   *
   * Thrown errors will be caught in the AuthGuard and handled appropriately.
   */
  protected checkUserRoles(
    _currentUser: UserInterface,
    _route: ActivatedRouteSnapshot,
  ): void | Promise<void> {
    // Optional function.
  }

  /**
   * This method handles the standard error which can occur in this auth guard.
   * It returns a Promise containing a boolean stating that the error is handled (or not).
   * The Auth Guard contains a fallback for when the Error isn't handled.
   *
   * This method can be overloaded when extending. If so start by calling the base
   * method. After which you can implement you own code to handle Errors which
   * aren't handled by this base class.
   *
   * This base class handle the following ErrorTypes.
   * - {@see InvalidRoleError}
   * - {@see InvalidTokenError}
   * - {@see ProfileCallFailsError}
   *
   * @Example
   * ```typescript
   *
   * @Injectable({
   *   providedIn: 'root',
   * })
   * export class OAuthLoginGuard extends OAuthGuard implements CanActivate {
   *
   *   protected async handleError(error: Error): Promise<boolean> {
   *     let response: boolean = await super.handleError(error);
   *     if (response) {
   *       return Promise.resolve(response);
   *     }
   *
   *     switch (true) {
   *       case error instanceof CustomProjectError:
   *         console.error('Handling Custom Project Error!');
   *         return this.oauthService.redirectToLoginPage();
   *     }
   *
   *     return Promise.resolve(false);
   *   }
   * }
   * ```
   */
  protected async handleError(error: Error): Promise<boolean | UrlTree> {
    switch (true) {
      case error instanceof InvalidTokenError:
        return this.oauthService.getLoginUrlTree();
      case error instanceof ProfileCallFailsError:
        await Promise.all([
          this.userService.logout(),
          this.oauthService.clearAuthenticationTokens(),
        ]);
        return this.oauthService.getLoginUrlTree();
      case error instanceof InvalidRoleError:
        await Promise.all([
          this.handleInvalidRoleError(),
          this.userService.logout(),
          this.oauthService.clearAuthenticationTokens(),
        ]);
        return this.oauthService.getLoginUrlTree();
    }
    return Promise.resolve(false);
  }

  /**
   * This method is executed when an InvalidRoleError was thrown. It presents an
   * Ionic toast, explaining this to the users.
   *
   * This method can be overridden to create your own warning for the user.
   */
  protected handleInvalidRoleError(): Promise<void> {
    return this.toastController
      .create({
        message: 'Je hebt niet de juiste rechten om in te loggen.',
        duration: 5000,
        color: 'danger',
        position: 'bottom',
      })
      .then((toast) => toast.present());
  }
}
