import { SortDirection } from './sort-direction.type';

/**
 * sort asc means that defined items come before undefined items.
 */
export function sortDefined(a: unknown, b: unknown, direction: SortDirection = 'asc'): number {
  if (a && b) {
    return 0;
  }

  if (!a && !b) {
    return 0;
  }

  return (a && !b ? 1 : -1) * (direction === 'asc' ? 1 : -1);
}
