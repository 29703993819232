export function getTextDimensions(element: HTMLElement): TextMetrics;
export function getTextDimensions(
  element: string,
  font?: string,
  letterspacing?: string,
): TextMetrics;

/**
 * This function returns the TextMetrics of a given text or text within a
 * HTMLElement. One of the mean properties of TextMetrics is that is give you
 * that exact pixel height and width of a text.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/TextMetrics about that
 * TextMetrics contains.
 * @param target (string | HTMLElement) In case of a HTMLElement it retrieves the
 * TextMetrics of the innerHTML otherwise it uses the string directly
 * @param font (string) the font used to calculate the text size. If target is a
 * string this property is mandatory otherwise it will extract the font style
 * from the Element.
 * @param letterSpacing (string) the font used to calculate the text letterspacing. If target is a
 * string this property is mandatory otherwise it will extract the font style
 * from the Element.
 */
//eslint-disable-next-line max-lines-per-function
export function getTextDimensions(
  target: string | HTMLElement,
  font?: string,
  letterSpacing?: string,
): TextMetrics {
  // if given, use cached canvas for better performance
  // else, create new canvas
  let canvas: HTMLCanvasElement = document.createElement('canvas');
  let context: CanvasRenderingContext2D = canvas.getContext('2d') as CanvasRenderingContext2D;
  let text: string;

  if (typeof target === 'string') {
    if (!font) {
      throw new Error(
        'If the first parameter is a string the second parameter (font) needs to be set as well.',
      );
    }
    text = target;
    context.font = font;
  } else {
    text = target.innerHTML;
    context.font = getElementFont(target);
  }

  if (font) {
    context.font = font;
  }

  let textMetrics: TextMetrics = context.measureText(text);

  // there is a bug in the calculate of textWidth when the actualBoundingBoxLeft is negative
  if (textMetrics.actualBoundingBoxLeft < 0) {
    textMetrics = {
      ...textMetrics,
      width: textMetrics.actualBoundingBoxLeft * 2 + textMetrics.actualBoundingBoxRight,
    };
  }
  if (letterSpacing) {
    if (letterSpacing === 'normal') {
      return textMetrics;
    }
    const numberOfCharacters: number = text.length;
    const spacing = letterSpacing.match(/([0-9.,]*)[\s]?.*/);
    if (spacing === null) {
      // couldn't parse letter spacing
      return textMetrics;
    }

    textMetrics = {
      ...textMetrics,
      width: textMetrics.width + +spacing[1] * numberOfCharacters,
    };
  }

  return textMetrics;
}

export function hasLargerFontSize(elementA: HTMLElement, elementB: HTMLElement): boolean {
  const widthA: number = getTextDimensions(
    'test',
    getElementFont(elementA),
    getElementLetterSpacing(elementA),
  ).width;
  const widthB: number = getTextDimensions(
    'test',
    getElementFont(elementB),
    getElementLetterSpacing(elementB),
  ).width;
  return widthA > widthB;
}

/**
 * This method can be used to extract the font style of a specfic HtmlElement.
 * Font style can be used to calculate the dimension of a text. {@see getTextDimensions()}
 *
 * @param element The Html element from which it extracts the font style.
 */
export function getElementFont(element: HTMLElement): string {
  // It's not recommended to use the short-hand font style because some browser like Firefox doesn't set this property
  // and only support the long-hand styles
  const fontSize: string = window.getComputedStyle(element, null).getPropertyValue('font-size');
  const fontFamily: string = window.getComputedStyle(element, null).getPropertyValue('font-family');
  const fontWeight: string = window.getComputedStyle(element, null).getPropertyValue('font-weight');
  return `${fontWeight} ${fontSize} ${fontFamily}`;
}

/**
 * This method can be used to extract the letter spacing of a specfic HtmlElement.
 * Font style can be used to calculate the dimension of a text. {@see getTextDimensions()}
 *
 * @param element The Html element from which it extracts the font style.
 */
export function getElementLetterSpacing(element: HTMLElement): string {
  return window.getComputedStyle(element, null).getPropertyValue('letter-spacing');
}

/**
 * Regex to find incompatible characters.
 */
const INCOMPATIBLE_CHARACTERS_REGEX: RegExp = /[+[\]()*.]/g;

/**
 * Regex to find whitespace characters.
 */
const WHITESPACE_CHARACTERS_REGEX: RegExp = /[-]/g;

/**
 * Normalizes search string. Doing the following:
 *  - Removing incompatible characters like: '+, [, ], (, ), *, .'.
 *  - Replaces characters like: '-' for a whitespace.
 *  - Changes Unicode Normalization Form of the string.
 *      https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
 *  - Removes accents from the string.
 *  - Change the string to lowercase.
 */
export function normalizeSearchString(searchString: string): string {
  return searchString
    .toString()
    .replace(INCOMPATIBLE_CHARACTERS_REGEX, '')
    .replace(WHITESPACE_CHARACTERS_REGEX, ' ')
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

/**
 * Normalizes text string. Doing to following:
 *  - Changes Unicode Normalization Form of the string.
 *      https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
 *  - Removes accents from the sting.
 *  - Change the string to lowercase.
 */
export function normalizeTextString(textString: string): string {
  return textString
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}
