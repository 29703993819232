/* istanbul ignore file */

/* eslint-disable */
import { ElementRef, Injector } from '@angular/core';
import { AbstractControl, NgControl, ValidationErrors, Validators } from '@angular/forms';

/**
 * We need to apply a 'hack' for the markAs function as long as Angular doesn't
 * fix the following issue: https://github.com/angular/angular/issues/10887.
 * This has to be done to make markAsDirty and markAsPristine work.
 *
 * This same hack is also done by Ionic to fix the classes on IonItem.
 * https://github.com/ionic-team/ionic-framework/blob/main/angular/src/directives/control-value-accessors/value-accessor.ts
 */
export function fixFormControlMarkAs(
  injector: Injector,
  formControl: AbstractControl,
  elementRef?: ElementRef,
  withValidation = false,
): void {
  fixFormControlAndOrIonClasses(injector, formControl, elementRef, withValidation);
}

export function setIconValidationClasses(injector: Injector, elementRef: ElementRef): void {
  fixFormControlAndOrIonClasses(injector, undefined, elementRef, false);
}

function fixFormControlAndOrIonClasses(
  injector: Injector,
  formControl?: AbstractControl,
  elementRef?: ElementRef,
  withValidation = false,
) {
  const event: CustomEvent<unknown> = new CustomEvent('ionStyle', {
    bubbles: true,
    detail: {
      interactive: true,
      input: true,
    },
  });

  if (elementRef) {
    elementRef.nativeElement.dispatchEvent(event);
  }

  const ngControl: NgControl = injector.get(NgControl);
  if (ngControl && elementRef) {
    if (ngControl.control?.hasValidator(Validators.required)) {
      const ionLabels: Element[] = Array.from(
        elementRef.nativeElement.querySelectorAll('ion-label'),
      );
      for (let child of ionLabels) {
        if (child.nodeName === 'ION-LABEL') {
          child.classList.add('required');
        }
      }
      const ionInputs: Element[] = Array.from(
        elementRef.nativeElement.querySelectorAll('ion-input'),
      );
      for (let child of ionInputs) {
        if (child.nodeName === 'ION-INPUT') {
          child.classList.add('required');
          child.setAttribute('required', 'true');
        }
      }
    }
    ngControl.statusChanges?.subscribe(() => {
      setIonicClasses(elementRef, !!ngControl.value);
      setHasValue(elementRef.nativeElement, ngControl);
    });
    fixMarkAsTouched(ngControl, formControl, elementRef);
    fixMarkAllAsTouched(ngControl, formControl, elementRef);
    fixMarkAsUntouched(ngControl, formControl, elementRef);
    fixMarkAsDirty(ngControl, formControl, elementRef);
    fixMarkAsPristine(ngControl, formControl, elementRef);
    if (withValidation) {
      fixSetError(ngControl, formControl, elementRef);
      if (formControl) {
        fixValidation(ngControl, formControl);
      }
    }
    setIonicClasses(elementRef, !!ngControl.value);
  }
}

/**
 * {@see fixFormControlMarkAs} for more information.
 */
function fixMarkAsTouched(
  ngControl: NgControl,
  formControl?: AbstractControl,
  elementRef?: ElementRef,
): void {
  if (ngControl.control) {
    const originalMarkAsTouched: (opts?: { onlySelf?: boolean }) => void = ngControl.control
      .markAsTouched as (opts?: { onlySelf?: boolean }) => void;
    ngControl.control.markAsTouched = (): void => {
      originalMarkAsTouched.apply(ngControl.control, arguments as any);
      if (formControl) {
        formControl.markAsTouched();
      }
      if (elementRef) {
        setIonicClasses(elementRef, !!ngControl.value);
      }
    };
  }
}

/**
 * {@see fixFormControlMarkAs} for more information.
 */
function fixMarkAllAsTouched(
  ngControl: NgControl,
  formControl?: AbstractControl,
  elementRef?: ElementRef,
): void {
  if (ngControl.control) {
    const originalMarkAllAsTouched: (opts?: { onlySelf?: boolean }) => void = ngControl.control
      .markAllAsTouched as (opts?: { onlySelf?: boolean }) => void;
    ngControl.control.markAllAsTouched = (): void => {
      originalMarkAllAsTouched.apply(ngControl.control, arguments as any);
      if (formControl) {
        formControl.markAllAsTouched();
      }
      if (elementRef) {
        setIonicClasses(elementRef, !!ngControl.value);
      }
    };
  }
}

/**
 * {@see fixFormControlMarkAs} for more information.
 */
function fixMarkAsUntouched(
  ngControl: NgControl,
  formControl?: AbstractControl,
  elementRef?: ElementRef,
): void {
  if (ngControl.control) {
    const originalMarkAsUntouched: (opts?: { onlySelf?: boolean }) => void = ngControl.control
      .markAsUntouched as (opts?: { onlySelf?: boolean }) => void;
    ngControl.control.markAsUntouched = (): void => {
      originalMarkAsUntouched.apply(ngControl.control, arguments as any);
      if (formControl) {
        formControl.markAsUntouched();
      }
      if (elementRef) {
        setIonicClasses(elementRef, !!ngControl.value);
      }
    };
  }
}

/**
 * {@see fixFormControlMarkAs} for more information.
 */
function fixMarkAsDirty(
  ngControl: NgControl,
  formControl?: AbstractControl,
  elementRef?: ElementRef,
): void {
  if (ngControl.control) {
    const originalMarkAsDirty: (opts?: { onlySelf?: boolean }) => void = ngControl.control
      .markAsDirty as (opts?: { onlySelf?: boolean }) => void;
    ngControl.control.markAsDirty = (): void => {
      originalMarkAsDirty.apply(ngControl.control, arguments as any);
      if (formControl) {
        formControl.markAsDirty();
      }
      if (elementRef) {
        setIonicClasses(elementRef, !!ngControl.value);
      }
    };
  }
}

/**
 * {@see fixFormControlMarkAs} for more information.
 */
function fixMarkAsPristine(
  ngControl: NgControl,
  formControl?: AbstractControl,
  elementRef?: ElementRef,
): void {
  if (ngControl.control) {
    const originalMarkAsPristine: (opts?: { onlySelf?: boolean }) => void = ngControl.control
      .markAsPristine as (opts?: { onlySelf?: boolean }) => void;
    ngControl.control.markAsPristine = (): void => {
      originalMarkAsPristine.apply(ngControl.control, arguments as any);
      if (formControl) {
        formControl.markAsPristine();
      }
      if (elementRef) {
        setIonicClasses(elementRef, !!ngControl.value);
      }
    };
  }
}

function fixSetError(
  ngControl: NgControl,
  formControl?: AbstractControl,
  elementRef?: ElementRef,
): void {
  if (ngControl.control) {
    const originalSetErrors: (
      errors: ValidationErrors | null,
      opts?: { emitEvent?: boolean },
    ) => void = ngControl.control.setErrors;
    ngControl.control.setErrors = (
      errors: ValidationErrors | null,
      opts?: { emitEvent?: boolean },
    ): void => {
      originalSetErrors.apply(ngControl.control, arguments as any);
      if (formControl) {
        formControl.setErrors(errors, opts);
      }
      if (elementRef) {
        setIonicClasses(elementRef, !!ngControl.value);
      }
    };
  }
}

function fixValidation(ngControl: NgControl, formControl: AbstractControl): void {
  if (ngControl.control) {
    const validators = [];
    if (formControl.validator) {
      validators.push(formControl.validator);
    }
    if (ngControl.control.validator) {
      validators.push(ngControl.control.validator);
    }

    formControl.setValidators(validators);
  }
}

//eslint-disable-next-line @typescript-eslint/naming-convention
declare const __zone_symbol__requestAnimationFrame: unknown;
declare const requestAnimationFrame: unknown;

export const raf: (h?: TimerHandler) => number = (h?: TimerHandler): number => {
  if (typeof __zone_symbol__requestAnimationFrame === 'function') {
    return __zone_symbol__requestAnimationFrame(h);
  }
  if (typeof requestAnimationFrame === 'function') {
    return requestAnimationFrame(h);
  }
  if (h) {
    return setTimeout(h);
  }
  return 0;
};

/**
 * This method is a Typed Copy of ionics own implementation.
 * @see https://github.com/ionic-team/ionic-framework/blob/main/angular/src/directives/control-value-accessors/value-accessor.ts
 */
function setIonicClasses(element: ElementRef, hasValue: boolean): void {
  raf(() => {
    const input: HTMLElement = element.nativeElement as HTMLElement;
    const classes: string[] = getClasses(input);
    setClasses(input, classes);

    const item: HTMLElement | null = input.closest('ion-item');

    if (item) {
      if (hasValue) {
        setClasses(item, [...classes, 'item-has-value']);
      } else {
        setClasses(item, classes);
      }
    }
    const ionInput: HTMLElement | null = input.closest('ion-input');
    if (ionInput) {
      if (hasValue) {
        setClasses(ionInput, [...classes, 'item-has-value']);
      } else {
        setClasses(ionInput, classes);
      }
    }
    const ttNumberInput: HTMLElement | null = input.closest('tt-number-input');
    if (ttNumberInput) {
      if (hasValue) {
        setClasses(ttNumberInput, [...classes, 'item-has-value']);
      } else {
        setClasses(ttNumberInput, classes);
      }
    }

    const directChildren: Element[] = Array.from(input.children);
    for (let child of directChildren) {
      if (child.nodeName === 'ION-INPUT') {
        setClasses(child as HTMLElement, classes);
      }
      if (child.nodeName === 'ION-ITEM') {
        setClasses(child as HTMLElement, classes);
      }
      if (child.nodeName === 'TT-NUMBER-INPUT') {
        setClasses(child as HTMLElement, classes);
      }
    }
  });
}

function setHasValue(element: HTMLElement, ngControl: NgControl): void {
  const classList: DOMTokenList = element.classList;
  if (ngControl.value) {
    classList.add('has-value');
  } else {
    classList.remove('has-value');
  }
}

/**
 * This method is a Typed Copy of ionics own implementation.
 * @see https://github.com/ionic-team/ionic-framework/blob/main/angular/src/directives/control-value-accessors/value-accessor.ts
 */
function getClasses(element: HTMLElement): string[] {
  const classList: DOMTokenList = element.classList;
  const classes: string[] = [];
  for (let i: number = 0; i < classList.length; i++) {
    const item: string | null = classList.item(i);
    if (item !== null && startsWith(item, 'ng-')) {
      classes.push(`ion-${item.slice(3)}`);
    }
  }
  return classes;
}

/**
 * This method is a Typed Copy of ionics own implementation.
 * @see https://github.com/ionic-team/ionic-framework/blob/main/angular/src/directives/control-value-accessors/value-accessor.ts
 */
function setClasses(element: HTMLElement, classes: string[]): void {
  const classList: DOMTokenList = element.classList;

  ['ion-valid', 'ion-invalid', 'ion-touched', 'ion-untouched', 'ion-dirty', 'ion-pristine'].forEach(
    (c) => classList.remove(c),
  );

  classes.forEach((c) => classList.add(c));
}

/**
 * This method is a Typed Copy of ionics own implementation.
 * @see https://github.com/ionic-team/ionic-framework/blob/main/angular/src/directives/control-value-accessors/value-accessor.ts
 */
function startsWith(input: string, search: string): boolean {
  return input.substring(0, search.length) === search;
}
