import { createSelector } from '@ngrx/store';
import { UsersSelectors } from './users.selectors';

export class UsersPermissionsSelectors {
  public static readonly isAdmin = createSelector(
    UsersSelectors.roles,
    (roles) => roles?.includes('ROLE_SKOLEO_CORE_ADMIN') ?? false,
  );

  public static readonly isReadOnlyAdmin = createSelector(
    UsersSelectors.roles,
    (roles) =>
      roles?.some((role) =>
        ['ROLE_SKOLEO_CORE_READ_ONLY_ADMIN', 'ROLE_SKOLEO_CORE_ADMIN'].includes(role),
      ) ?? false,
  );

  public static readonly isCoordinator = createSelector(
    UsersSelectors.roles,
    (roles) =>
      roles?.some((role) =>
        ['ROLE_SKOLEO_CORE_COORDINATOR', 'ROLE_SKOLEO_CORE_ADMIN'].includes(role),
      ) ?? false,
  );

  public static readonly isSchoolContact = createSelector(
    UsersSelectors.roles,
    (roles) =>
      roles?.some((role) =>
        ['ROLE_SKOLEO_CORE_SCHOOLCONTACT', 'ROLE_SKOLEO_CORE_ADMIN'].includes(role),
      ) ?? false,
  );
}
