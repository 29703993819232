import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'kebabCase',
  standalone: true,
})
export class KebabCasePipe implements PipeTransform {
  /** Used to match words composed of alphanumeric characters. */
  private readonly wordRegex: RegExp = /(?:[A-Z]{0,1}[a-z]+)+?/g;

  public transform(value: unknown, ...args: unknown[]): unknown {
    if (typeof value === 'string') {
      return this.kebabCase(value);
    }
    return value;
  }

  public kebabCase(value: string): string {
    return this.words(value.replace(/['\u2019]/g, '')).reduce(
      (result, word, index) => result + (index ? '-' : '') + word.toLowerCase(),
      '',
    );
  }

  public wordsMatch(value: string): RegExpMatchArray | null {
    return value.match(this.wordRegex);
  }

  public words(
    value: string,
    pattern?: { [Symbol.match](matchPattern: string): RegExpMatchArray | null },
  ): string[] {
    if (pattern === undefined) {
      const result: RegExpMatchArray | null = this.wordsMatch(value);
      return result || [];
    }
    return value.match(pattern) || [];
  }
}
