import { NgModule } from '@angular/core';
import { HighlightSearchPipe } from './highlight-search.pipe';

/**
 * @deprecated use HighlightSearchPipe as standalone component
 * will be removed in v14
 */
@NgModule({
  exports: [HighlightSearchPipe],
  imports: [HighlightSearchPipe],
})
export class HighlightSearchModule {}
