import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { SentryErrorHandlerMock } from './sentry-error-handler.service.mock';
import { SentryWebConfig } from './sentry-web.config';

/**
 * @deprecated use provider
 */
@NgModule({
  providers: [
    { provide: SentryWebConfig, useValue: {} },
    { provide: SentryErrorHandler, useClass: SentryErrorHandlerMock },
    { provide: ErrorHandler, useExisting: SentryErrorHandler },
  ],
})
export class SentryWebTestingModule {
  public static forRoot(options: SentryWebConfig): ModuleWithProviders<SentryWebTestingModule> {
    return {
      ngModule: SentryWebTestingModule,
      providers: [
        { provide: SentryWebConfig, useValue: options },
        { provide: SentryErrorHandler, useClass: SentryErrorHandlerMock },
        { provide: ErrorHandler, useExisting: SentryErrorHandler },
      ],
    };
  }
}
