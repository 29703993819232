import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PKCEGuard } from './pkce/pkce.guard';

/**
 * @deprecated use {@see provideTtAuth} function
 * this doesn't work any more you need to inject the interceptor yourself
 *
 * @usageNotes
 * ```typescript
 * await bootstrapApplication(RootComponent, {
 *   providers: [
 *     provideHttpClient(
 *       withInterceptors([jsonLdInterceptor, authInterceptor])
 *     ),
 *   ]
 * });
 * ```
 */
export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'authenticate',
    pathMatch: 'full',
  },
  {
    path: 'authenticate',
    loadComponent: () => import('./authenticate/authenticate.page').then((m) => m.AuthenticatePage),
  },
  {
    path: 'ios-login.html',
    redirectTo: 'authenticate',
  },
];

/**
 * @deprecated use {@see provideTtAuth} function
 */
@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
