import { NgModule } from '@angular/core';
import { AsTypePipe } from './as-type.pipe';

/**
 * @deprecated use AsTypePipe as standalone component
 * will be removed in v14
 */
@NgModule({
  exports: [AsTypePipe],
  imports: [AsTypePipe],
})
export class AsTypeModule {}
