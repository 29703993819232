import { Component, inject, OnInit } from '@angular/core';
import { OAuthService } from '../shared/oauth/oauth.service';

@Component({
  selector: 'tt-authenticate',
  template: '',
  standalone: true,
  imports: [],
})
export class AuthenticatePage implements OnInit {
  private oauthService = inject(OAuthService);

  /**
   * The only purpose for this page is to redirect the user to the dashboard page
   */
  public async ngOnInit(): Promise<void> {
    const loggedIn: boolean = await this.oauthService.hasValidAccessToken();

    if (loggedIn) {
      await this.oauthService.redirectAfterLogin();
    } else {
      await this.oauthService.redirectToLoginPage();
    }
  }
}
