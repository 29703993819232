import { Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { OAuthService } from '../shared/oauth/oauth.service';

export async function pkceAuthentication(
  oauthService: OAuthService,
  router: Router,
): Promise<boolean | UrlTree> {
  const url: URL = new URL(location.toString());
  const queryParams: Record<string, unknown> = {};
  for (let [key, value] of url.searchParams.entries()) {
    queryParams[key] = value;
  }
  const authCodePKCE = queryParams['code'] as string;

  if (authCodePKCE) {
    await firstValueFrom(oauthService.exchangeAuthCodePKCE(authCodePKCE), {
      defaultValue: undefined,
    });
    // we do not want to display the code in the address bar so
    // do a redirect to the home or save origin page including the left
    // over queryParameters.
    return router.navigateByUrl(await oauthService.getRedirectAfterLoginUrlTree());
  }

  return true;
}
