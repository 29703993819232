export enum Environment {
  PRODUCTION = 'production',
  LOCAL = 'local',
  TESTING = 'testing',
  ACCEPTANCE = 'acceptance',
}
/**
 * This method either returns the originale {@see console} object of a mock which
 * emits nothing.
 *
 * It either except a {@see SentryEnvironment} on which it only emits console logs
 * on {@see SentryEnvironment.LOCAL} or a boolean on which it emits console logs
 * on true.
 *
 * @example
 * ```typescript
 *   consoleInDev(this.debug).log('Missing cache map requesting');
 * ```
 */
export function consoleInDev(environment: Environment | boolean): Console {
  if (typeof environment === 'boolean' && environment) {
    return console;
  } else if (environment === Environment.LOCAL) {
    return console;
  }

  return new SilenceConsole() as Console;
}

/**
 * A mock implementation of Console which emits no log messages.
 */
export class SilenceConsole implements Console {
  //eslint-disable-next-line @typescript-eslint/naming-convention
  public Console: never = undefined as never;

  public memory: never = undefined as never;

  public assert(value: unknown, message?: string, ...optionalParams: unknown[]): void;
  public assert(condition?: boolean, ...data: unknown[]): void;
  public assert(value?: unknown, ...message: unknown[]): void {
    /* do nothing */
  }

  public clear(): void {
    /* do nothing */
  }

  public count(label?: string): void;
  public count(label?: string): void;
  public count(label?: string): void {
    /* do nothing */
  }

  public countReset(label?: string): void;
  public countReset(label?: string): void;
  public countReset(label?: string): void {
    /* do nothing */
  }

  public debug(message?: unknown, ...optionalParams: unknown[]): void;
  public debug(...data: unknown[]): void;
  public debug(...message: unknown[]): void {
    /* do nothing */
  }

  public dir(obj: unknown, options?: unknown): void;
  public dir(item?: unknown, options?: unknown): void;
  public dir(obj?: unknown, options?: unknown): void {
    /* do nothing */
  }

  public dirxml(...data: unknown[]): void {
    /* do nothing */
  }

  public error(message?: unknown, ...optionalParams: unknown[]): void;
  public error(...data: unknown[]): void;
  public error(...message: unknown[]): void {
    /* do nothing */
  }

  /* istanbul ignore next The console used by jest doesn't have this method */
  public exception(message?: string, ...optionalParams: unknown[]): void {
    /* do nothing */
  }

  public group(...label: unknown[]): void;
  public group(...data: unknown[]): void;
  public group(...label: unknown[]): void {
    /* do nothing */
  }

  public groupCollapsed(...label: unknown[]): void;
  public groupCollapsed(...data: unknown[]): void;
  public groupCollapsed(...label: unknown[]): void {
    /* do nothing */
  }

  public groupEnd(): void {
    /* do nothing */
  }

  public info(message?: unknown, ...optionalParams: unknown[]): void;
  public info(...data: unknown[]): void;
  public info(...message: unknown[]): void {
    /* do nothing */
  }

  public log(message?: unknown, ...optionalParams: unknown[]): void;
  public log(...data: unknown[]): void;
  public log(...message: unknown[]): void {
    /* do nothing */
  }

  /* istanbul ignore next The console used by jest doesn't have this method */
  public profile(label?: string): void {
    /* do nothing */
  }

  /* istanbul ignore next The console used by jest doesn't have this method */
  public profileEnd(label?: string): void {
    /* do nothing */
  }

  public table(tabularData: unknown, properties?: readonly string[]): void;
  public table(tabularData?: unknown, properties?: string[]): void;
  public table(tabularData?: unknown, properties?: readonly string[] | string[]): void {
    /* do nothing */
  }

  public time(label?: string): void;
  public time(label?: string): void;
  public time(label?: string): void {
    /* do nothing */
  }

  public timeEnd(label?: string): void;
  public timeEnd(label?: string): void;
  public timeEnd(label?: string): void {
    /* do nothing */
  }

  public timeLog(label?: string, ...data: unknown[]): void;
  public timeLog(label?: string, ...data: unknown[]): void;
  public timeLog(label?: string, ...data: unknown[]): void {
    /* do nothing */
  }

  /* istanbul ignore next The console used by jest doesn't have this method */
  public timeStamp(label?: string): void;
  public timeStamp(label?: string): void;
  public timeStamp(label?: string): void {
    /* do nothing */
  }

  public trace(message?: unknown, ...optionalParams: unknown[]): void;
  public trace(...data: unknown[]): void;
  public trace(...message: unknown[]): void {
    /* do nothing */
  }

  public warn(message?: unknown, ...optionalParams: unknown[]): void;
  public warn(...data: unknown[]): void;
  public warn(...message: unknown[]): void {
    /* do nothing */
  }

  /**
   * @deprecated Use console.timeStamp() instead.
   */
  /* istanbul ignore next The console used by jest doesn't have this method */
  public markTimeline(label?: string): void {
    /* do nothing */
  }

  /**
   * @deprecated Use console.time() instead.
   */
  /* istanbul ignore next The console used by jest doesn't have this method */
  public timeline(label?: string): void {
    /* do nothing */
  }

  /**
   * @deprecated Use console.timeEnd() instead.
   */
  /* istanbul ignore next The console used by jest doesn't have this method */
  public timelineEnd(label?: string): void {
    /* do nothing */
  }
}
