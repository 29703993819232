import { JestFn } from '@techniek-team/jest-utils';
import { isDefined, firstEmitFrom } from '@techniek-team/rxjs';
import { LyceoUser } from '../../models/lyceo-user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { USER_PROFILE } from '../../api/auth/auth.response.mock';

export const USER_MOCK: LyceoUser = new LyceoUser(USER_PROFILE);

export class UserServiceMock<T> {
  public _user$: BehaviorSubject<T | undefined> = new BehaviorSubject<T | undefined>(undefined);

  public isAuthenticated: JestFn<Promise<boolean>> = jest.fn(() => {
    return Promise.resolve(true);
  });

  public getUser: JestFn<Promise<T | undefined>> = jest.fn(() => {
    return firstEmitFrom(this._user$);
  });

  public currentUser: JestFn<Observable<T>> = jest.fn(() => {
    return this._user$.pipe(isDefined());
  });

  public getRoles: JestFn<Promise<string[]>> = jest.fn(() => {
    return Promise.resolve(USER_MOCK.roles);
  });

  public hasRole: JestFn<boolean> = jest.fn((_role: string) => {
    return true;
  });

  public hasOneOfRoles: JestFn<boolean> = jest.fn((_roles: string[]) => {
    return true;
  });

  public updateProfile: JestFn<Promise<T | LyceoUser>> = jest.fn(() => {
    return Promise.resolve(USER_MOCK);
  });

  public logout: JestFn<Promise<void>> = jest.fn(() => {
    this._user$.next(undefined);
    return Promise.resolve();
  });

  public requestIfTheUserWantsToLogOut: JestFn<void> = jest.fn();

  public handleLogoutAction: JestFn<Promise<void>> = jest.fn(() => Promise.resolve());

  public isGranted: JestFn<boolean> = jest.fn((_requiredGrants: string[]) => {
    return true;
  });
}
