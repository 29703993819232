import { ENVIRONMENT_INITIALIZER, importProvidersFrom } from '@angular/core';
import { IonicStorageModule, Storage, StorageConfig } from '@ionic/storage-angular';
import { StorageMock } from '@techniek-team/ionic-mocks';

export function provideIonicStorageTesting(storageConfig?: StorageConfig) {
  return [
    importProvidersFrom(IonicStorageModule.forRoot(storageConfig)),
    { provide: Storage, useClass: StorageMock },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useFactory: (storage: Storage) => {
        return () => storage.create();
      },
      multi: true,
      deps: [Storage],
    },
  ];
}
