import { NgModule } from '@angular/core';
import { TtConvertToColorPipe } from './convert-to-color.pipe';

/**
 * @deprecated use standalone instead
 */
@NgModule({
  imports: [TtConvertToColorPipe],
  exports: [TtConvertToColorPipe],
})
export class TtConvertToColorModule {}
