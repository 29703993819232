import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

type FormArrayValidatorFn = (
  control: AbstractControl | AbstractControl[],
) => ValidationErrors | null;

// Without this dynamic parameter AOT compiling will fail.
// @see https://angular.io/guide/angular-compiler-options#strictmetadataemit
// @dynamic
export class ArrayValidators {
  /**
   * FormControl validator which check if a formArray contains
   * min number of items.
   */
  public static minLength(min: number): ValidatorFn | FormArrayValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        throw new Error("The 'minLength' validator only supports an instance of FormArray.");
      }
      return control.length < min ? { minLength: true } : null;
    };
  }

  /**
   * FormControl validator which checks if a FormArray contains
   * min number of valid FormControls.
   * Incorrect items in the FormArray are ignored.
   */
  public static minLengthAndValid(min: number): ValidatorFn | FormArrayValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        // istanbul ignore next
        throw new Error(
          "The 'minLengthAndValid' validator only supports an instance of FormArray.",
        );
      }

      let errors: Record<string, ValidationErrors> = {};

      const validControls: AbstractControl[] = control.controls.filter(
        (childControl: AbstractControl, index) => {
          const validation: ValidationErrors | null = ArrayValidators.validateControl(childControl);
          if (validation !== null) {
            errors[index] = validation;
          }
          return validation === null;
        },
      );

      return validControls.length < min ? { shouldContainAtLeast: errors } : null;
    };
  }

  /**
   * FormControl validator which check if a formArray contains
   * max number of items.
   */
  public static maxLength(max: number): ValidatorFn | FormArrayValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        throw new Error("The 'maxLength' validator only supports an instance of FormArray.");
      }
      return control.length > max ? { maxLength: true } : null;
    };
  }

  /**
   * FormControl validator which checks if a FormArray contains
   * max number of valid FormControls.
   * Incorrect items in the FormArray are ignored.
   */
  public static maxLengthAndValid(max: number): ValidatorFn | FormArrayValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        // istanbul ignore next
        throw new Error(
          "The 'maxLengthAndValid' validator only supports an instance of FormArray.",
        );
      }

      let errors: Record<string, ValidationErrors> = {};

      const validControls: AbstractControl[] = control.controls.filter(
        (childControl: AbstractControl, index) => {
          const validation: ValidationErrors | null = ArrayValidators.validateControl(childControl);
          if (validation !== null) {
            errors[index] = validation;
          }
          return validation === null;
        },
      );

      return validControls.length > max ? { shouldContainAtLeast: errors } : null;
    };
  }

  /**
   * FormControl validator which check if a formArray contains between
   * min and max number of items.
   */
  public static betweenLength(min: number, max: number): ValidatorFn | FormArrayValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        // istanbul ignore next
        throw new Error("The 'betweenLength' validator only supports an instance of FormArray.");
      }
      return control.length < min || control.length > max ? { betweenLength: true } : null;
    };
  }

  /**
   * FormControl validator which checks if a FormArray contains between
   * min and max number of valid FormControls.
   * Incorrect items in the FormArray are ignored.
   */
  public static betweenLengthAndValid(
    min: number,
    max: number,
  ): ValidatorFn | FormArrayValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        // istanbul ignore next
        throw new Error(
          "The 'betweenLengthAndValid' validator only supports an instance of FormArray.",
        );
      }

      let errors: Record<string, ValidationErrors> = {};

      const validControls: AbstractControl[] = control.controls.filter(
        (childControl: AbstractControl, index) => {
          const validation: ValidationErrors | null = ArrayValidators.validateControl(childControl);
          if (validation !== null) {
            errors[index] = validation;
          }
          return validation === null;
        },
      );

      return validControls.length < min || validControls.length > max
        ? { shouldContainAtLast: errors }
        : null;
    };
  }

  /**
   * This function executes the validators of the given AbstractControl and
   * any or all child AbstractControls and returns the first ValidationError
   * found or null if all the child controls are valid.
   *
   * This function should not be used as a ValidatorFn itself.
   */
  public static validateControl(control: AbstractControl): ValidationErrors | null {
    if (control.validator !== null) {
      const validation: ValidationErrors | null = control.validator(control);
      if (validation !== null) {
        return validation;
      }
    }

    if (control instanceof FormGroup) {
      for (let [name, childControl] of Object.entries(control.controls)) {
        const validation: ValidationErrors | null = ArrayValidators.validateControl(childControl);
        if (validation !== null) {
          return { [name]: validation };
        }
      }
    }

    if (control instanceof FormArray) {
      for (let [index, childControl] of control.controls.entries()) {
        const validation: ValidationErrors | null = ArrayValidators.validateControl(childControl);
        if (validation !== null) {
          return { ['formArray[' + index + ']']: validation };
        }
      }
    }

    return null;
  }

  /**
   * The validator checks if the formArray contains at least one of the
   * containing FormGroups has a FormControl with the given formControlName
   * and that FormControl is valid based on the passed validator.
   *
   * This validator assumes that the given FormArray holds an array of
   * FormGroups.
   */
  public static containsAny(
    formControlName: string,
    validator: ValidatorFn,
  ): ValidatorFn | FormArrayValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!(control instanceof FormArray)) {
        // istanbul ignore next
        throw new Error("The 'betweenLength' validator only supports an instance of FormArray.");
      }

      const validControls: AbstractControl[] = control.controls.filter(
        (childControl: AbstractControl) => {
          const targetControl: AbstractControl | null = childControl.get(formControlName);
          if (targetControl === null) {
            return false;
          }
          return validator(targetControl) === null;
        },
      );

      return validControls.length > 0
        ? null
        : {
            arrayAny: `None of the FormGroups.${formControlName} validates to the passed ValidatorFn.`,
          };
    };
  }
}
