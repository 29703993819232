import { DatePipeConfig, DecimalPipe } from '@angular/common';
import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { TtDateRangePipe } from '../tt-date-range/tt-date-range.pipe';

/**
 * @deprecated use IntRangeInterface instead and move to tt-range packages. import it from `@techniek-team/tt-range` instead.
 * Will be removed after next major release v16.
 */
interface NumberRangeInterface extends IntRangeInterface {}

/**
 * @deprecated move to tt-range packages. import it from `@techniek-team/tt-range` instead.
 * Will be removed after next major release v16.
 */
interface IntRangeInterface {
  start: number;
  end: number;
  inclusiveStart: boolean;
  inclusiveEnd: boolean;
}

/**
 * @deprecated move to tt-range packages. import it from `@techniek-team/tt-range` instead.
 * Will be removed after next major release v16.
 */
@Pipe({
  name: 'ttNumberRange',
  standalone: true,
})
export class TtNumberRangePipe implements PipeTransform {
  public transform(
    value: NumberRangeInterface | number,
    options: {
      format?: string;
      divider?: string;
      shorten?: boolean;
      locale?: string;
    },
  ): string;
  public transform(
    value: null | undefined,
    options: {
      format?: string;
      divider?: string;
      shorten?: boolean;
      locale?: string;
    },
  ): null;
  //eslint-disable-next-line complexity
  public transform(
    value: NumberRangeInterface | number | null | undefined,
    options: {
      format?: string;
      divider?: string;
      shorten?: boolean;
      locale?: string;
    },
  ): string | null {
    options = { divider: '-', shorten: true, ...options };
    const pipe = new DecimalPipe(options.locale ?? 'nl-NL');
    const formatString: string = options.format ?? '1.0-3';

    if (!value) {
      return null;
    }

    if (typeof value === 'number') {
      return pipe.transform(value, options.format, options.locale);
    }
    if (!this.isNumberRange(value)) {
      return value;
    }
    const startString: string = pipe.transform(value.start, options.format, options.locale) ?? '';
    const endString: string = pipe.transform(value.end, options.format, options.locale) ?? '';

    // check if format doesn't contain any smaller than a day
    if (options.shorten && startString === endString) {
      return `${startString}`;
    }

    return `${startString}${options.divider}${endString}`;
  }

  private isNumberRange(value: NumberRangeInterface): value is NumberRangeInterface {
    if (!('start' in value && 'end' in value)) {
      return false;
    }

    return typeof value.start === 'number' && typeof value.end === 'number';
  }
}
