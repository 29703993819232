type SortFunction<T> = (a: T, b: T) => number;

class SortChain<T> {
  public predicate!: SortFunction<T>;

  constructor(
    private a: T,
    private b: T,
  ) {}

  public pipe(...sortPredicates: SortFunction<T>[]): number {
    for (let predicate of sortPredicates) {
      const sortPredicateResult: number = predicate(this.a, this.b);
      //noinspection SuspiciousTypeOfGuard
      if (!(typeof sortPredicateResult === 'number')) {
        throw Error('Predicate does not return a number');
      }
      if (sortPredicateResult !== 0) {
        return sortPredicateResult;
      }
    }
    return 0;
  }
}

export function sortChain<T>(a: T, b: T): SortChain<T> {
  return new SortChain<T>(a, b);
}
