import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { isDefined } from '@techniek-team/rxjs';
import { UsersPermissionsSelectors } from './+state/users-permissions.selectors';
import { usersActions } from './+state/users.actions';
import { UsersSelectors } from './+state/users.selectors';

@Injectable({ providedIn: 'root' })
export class UsersStoreService {
  private readonly store = inject(Store);

  public readonly loading = this.store.selectSignal(UsersSelectors.selectUserLoading);

  public readonly loaded = this.store.selectSignal(UsersSelectors.selectUserLoaded);

  public readonly user = this.store.selectSignal(UsersSelectors.activeUser);

  public user$ = this.store.pipe(select(UsersSelectors.activeUser)).pipe(isDefined());

  public readonly isAdmin = this.store.selectSignal(UsersPermissionsSelectors.isAdmin);

  public readonly isReadOnlyAdmin = this.store.selectSignal(
    UsersPermissionsSelectors.isReadOnlyAdmin,
  );

  public readonly isCoordinator = this.store.selectSignal(UsersPermissionsSelectors.isCoordinator);

  public readonly isSchoolContact = this.store.selectSignal(
    UsersPermissionsSelectors.isSchoolContact,
  );

  public init(): void {
    this.store.dispatch(usersActions.initUser());
  }
}
