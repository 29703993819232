/* eslint-disable @typescript-eslint/no-explicit-any */
import { EMPTY, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LyceoUser } from '../../models/lyceo-user.model';
import { Token } from '../../models/token.model';
import { UserInterface } from '../../shared/user/user.interface';
import { AUTH_TOKEN_RESPONSE_MOCK, USER_PROFILE } from './auth.response.mock';

export class AuthApiMock<T extends UserInterface = LyceoUser> {
  public getProfile: () => Observable<unknown> = jest.fn(() => {
    return of(USER_PROFILE).pipe(
      map(
        (response: Record<string, any>) =>
          new LyceoUser({
            //eslint-disable-next-line @typescript-eslint/naming-convention
            person_id: response['person_id'],
            email: response['email'],
            //eslint-disable-next-line @typescript-eslint/naming-convention
            first_name: response['first_name'],
            //eslint-disable-next-line @typescript-eslint/naming-convention
            middle_name: response['middle_name'],
            //eslint-disable-next-line @typescript-eslint/naming-convention
            last_name: response['last_name'],
            //eslint-disable-next-line @typescript-eslint/naming-convention
            full_name: response['full_name'],
            roles: response['roles'],
            //eslint-disable-next-line @typescript-eslint/naming-convention
            id: response['skoleo_id'],
          }),
      ),
    );
  });

  public logout: () => Observable<void> = jest.fn(() => {
    return EMPTY;
  });

  public getToken: (code: string, codeVerifier: string, redirectUri: string) => Observable<Token> =
    jest.fn(() => {
      return of(AUTH_TOKEN_RESPONSE_MOCK).pipe(
        map(
          (response) =>
            new Token(
              response['token_type'],
              response['access_token'],
              response['expires_in'],
              response['refresh_token'],
            ),
        ),
      );
    });

  public refreshAccessToken: (refreshToken: string) => Observable<Token> = jest.fn(() => {
    return of(AUTH_TOKEN_RESPONSE_MOCK).pipe(
      map(
        (response) =>
          new Token(
            response['token_type'],
            response['access_token'],
            response['expires_in'],
            response['refresh_token'],
          ),
      ),
    );
  });
}
