import {
  animate,
  animation,
  AnimationReferenceMetadata,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';

export const blockEnterFromCornerAnimation: AnimationReferenceMetadata = animation([
  state(
    'in',
    style({
      'transform': 'scaleY(0) scaleX(0)',
      'transform-origin': 'top left',
    }),
  ),
  animate('500ms cubic-bezier(0.05, 0.7, 0.1, 1.0)', style({ transform: 'scaleY(1) scaleX(1)' })),
]);

export const blockLeaveFromCornerAnimation: AnimationReferenceMetadata = animation([
  style({
    'transform': 'scaleY(1) scaleX(1)',
    'transform-origin': 'bottom right',
  }),
  animate('100ms cubic-bezier(0.3, 0.0, 0.8, 0.15)', style({ transform: 'scaleY(0) scaleX(0)' })),
]);

export const blockEnterFromCorner: AnimationTriggerMetadata[] = [
  trigger('blockFromCorner', [
    transition(':enter', useAnimation(blockEnterFromCornerAnimation)),
    transition(':leave', useAnimation(blockLeaveFromCornerAnimation)),
  ]),
];
