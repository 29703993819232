import { AbstractControl, UntypedFormControl, ValidationErrors } from '@angular/forms';

export class TypeValidators {
  /**
   * FormControl validator which check if the given formControl
   * contains a boolean.
   */
  public static isBoolean(control: AbstractControl): ValidationErrors | null {
    if (!(control instanceof UntypedFormControl)) {
      throw new Error("The 'FormControl' validator only supports an instance of FormArray.");
    }

    return typeof control.value !== 'boolean' ? { mustBeOfTypeBoolean: true } : null;
  }
}
