import { SentryEnvironment } from '@techniek-team/sentry-web';
import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  environment: SentryEnvironment.PRODUCTION,
  release: 'v2.5.9',
  sentryDsn: 'https://f341ed93fc24409b8a408176e636449e@errors.techniek-team.nl//145',

  coreApiUrl: 'https://api.ci.mijnskoleo.nl',
  dashboardApiUrl: 'https://traject.lyceo.nl',

  ssoConfig: {
    clientId: 'f8ab4621-692a-42c4-bf98-95701e99c94e',
    profileBaseUrl: 'https://sso.lyceo.nl/api/v1/profile',
    ssoBaseUrl: 'https://sso.lyceo.nl',
    baseUrl: 'https://school.lyceo.nl/',
    redirectUri: 'https://school.lyceo.nl/',
  },
};
