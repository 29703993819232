/* eslint-disable @typescript-eslint/no-explicit-any */
import { EnvironmentProviders, Provider } from '@angular/core';
import { AuthApi } from './api/auth/auth.api';
import { AuthApiMock } from './api/auth/auth.api.mock';
import { AuthConfig } from './auth.config';
import { InAppBrowserWebService } from './shared/in-app-browser/in-app-browser-web.service';
import { InAppBrowserService } from './shared/in-app-browser/in-app-browser.service';
import { OAuthService } from './shared/oauth/oauth.service';
import { OAuthServiceMock } from './shared/oauth/oauth.service.mock';
import { UserInterface } from './shared/user/user.interface';
import { UserService } from './shared/user/user.service';
import { UserServiceMock } from './shared/user/user.service.mock';

export function provideTtAuthTesting<T extends UserInterface>(
  options: AuthConfig<T>,
  ...features: (Provider | EnvironmentProviders)[]
) {
  options = { ...new AuthConfig(), ...options };
  return [
    { provide: AuthConfig, useValue: options },
    InAppBrowserWebService,
    {
      provide: InAppBrowserService,
      useExisting: InAppBrowserWebService,
    },
    { provide: AuthApi, useClass: AuthApiMock },
    { provide: UserService, useClass: UserServiceMock },
    { provide: OAuthService, useClass: OAuthServiceMock },
    ...features,
  ];
}
