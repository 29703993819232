//eslint-disable-next-line import/no-mutable-exports
export let characterSize: number = 1;

export function mockGetTextDimensions(element: HTMLElement): TextMetrics;
export function mockGetTextDimensions(element: string, font?: string): TextMetrics;
export function mockGetTextDimensions(target: string | HTMLElement, font?: string): TextMetrics {
  let text: string;

  if (typeof target === 'string') {
    if (!font) {
      throw new Error(
        'If the first parameter is a string the second parameter (font) needs to be set as well.',
      );
    }
    text = target;
  } else {
    text = target.innerHTML;
  }

  return {
    actualBoundingBoxAscent: 0,
    actualBoundingBoxDescent: 0,
    actualBoundingBoxLeft: 0,
    actualBoundingBoxRight: 0,
    alphabeticBaseline: 0,
    emHeightAscent: 0,
    emHeightDescent: 0,
    fontBoundingBoxAscent: 0,
    fontBoundingBoxDescent: 0,
    hangingBaseline: 0,
    ideographicBaseline: 0,
    width: text.length * characterSize,
  } as TextMetrics;
}

export function mockCharacterSize(size: number): void {
  characterSize = size;
}
