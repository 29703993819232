import { animate, animation, AnimationReferenceMetadata, style } from '@angular/animations';

export const tableEnterAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 0, lineHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  animate(
    '500ms cubic-bezier(0.05, 0.7, 0.1, 1.0)',
    style({
      maxHeight: 50,
      lineHeight: 1.5,
      paddingTop: '10px',
      paddingBottom: '10px',
    }),
  ),
]);

export const tableLeaveAnimation: AnimationReferenceMetadata = animation([
  style({
    maxHeight: 50,
    lineHeight: 1.5,
    paddingTop: '10px',
    paddingBottom: '10px',
  }),
  animate(
    '200ms cubic-bezier(0.3, 0.0, 0.8, 0.15)',
    style({ maxHeight: 0, lineHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  ),
]);

export const tableLoadingEnterAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 0 }),
  animate('200ms cubic-bezier(0.05, 0.7, 0.1, 1.0)', style({ maxHeight: 4 })),
]);

export const tableLoadingLeaveAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 4 }),
  animate(
    '200ms cubic-bezier(0.3, 0.0, 0.8, 0.15)',
    style({ maxHeight: 0, lineHeight: 0, padding: '0 16px' }),
  ),
]);

export const noTableDataEnterAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  animate(
    '500ms cubic-bezier(0.05, 0.7, 0.1, 1.0)',
    style({ maxHeight: 100, paddingTop: '10px', paddingBottom: '10px' }),
  ),
]);

export const noTableDataLeaveAnimation: AnimationReferenceMetadata = animation([
  style({ maxHeight: 100, paddingTop: '10px', paddingBottom: '10px' }),
  animate(
    '200ms cubic-bezier(0.3, 0.0, 0.8, 0.15)',
    style({ maxHeight: 0, paddingTop: '0', paddingBottom: '0' }),
  ),
]);
