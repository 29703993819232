import { APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig } from './auth.config';
import { pkceAuthentication } from './pkce/pkce-authentication.function';
import { InAppBrowserWebService } from './shared/in-app-browser/in-app-browser-web.service';
import { InAppBrowserService } from './shared/in-app-browser/in-app-browser.service';
import { OAuthService } from './shared/oauth/oauth.service';
import { UserInterface } from './shared/user/user.interface';

export class AuthModule {
  public static forRoot<T extends UserInterface>(
    options: AuthConfig<T>,
  ): ModuleWithProviders<AuthModule> {
    options = { ...new AuthConfig(), ...options };

    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: options },
        { provide: InAppBrowserService, useClass: InAppBrowserWebService },
        {
          provide: APP_INITIALIZER,
          useFactory: (oAuthService: OAuthService, router: Router) => () =>
            pkceAuthentication(oAuthService, router),
          multi: true,
          deps: [OAuthService, Router],
        },
      ],
    };
  }
}
