import { HttpErrorResponse } from '@angular/common/http';
import { SentryWebConfig } from './sentry-web.config';
import { ServerResponseError } from './server-response.error';

interface ErrorCandidate {
  name?: unknown;
  message?: unknown;
  stack?: unknown;
}

interface ServerResponseErrorCandidate {
  code: number;
  message: string;
}

export class SentryErrorHandlerMock {
  public config: SentryWebConfig = new SentryWebConfig();

  public handleError: (error: unknown) => void = jest.fn();

  public captureError: (error: unknown) => Promise<string> = jest.fn(() => {
    return Promise.resolve('error-id-1');
  });

  public captureMessage: (error: string) => Promise<void> = jest.fn(() => {
    return Promise.resolve();
  });

  //eslint-disable-next-line complexity
  public extractMessage(
    e: unknown,
    fallbackMessage: string = 'Oeps, er is iets misgegaan.',
  ): string {
    const mapping: Record<string, string> = this.config?.errorMessageMapping ?? {};
    const error: unknown = this.extractError(e);

    if (error instanceof ServerResponseError) {
      if (mapping[error.message]) {
        return mapping[error.message];
      }
      return error.message;
    }

    if (error instanceof Error) {
      if (mapping[error.message]) {
        return mapping[error.message];
      }
      return fallbackMessage;
    }

    if (typeof error === 'string') {
      if (mapping[error]) {
        return mapping[error];
      }
      return fallbackMessage;
    }

    if (error && typeof error === 'object' && 'toString' in error) {
      if (mapping[error.toString()]) {
        return mapping[error.toString()];
      }
      return fallbackMessage;
    }

    return fallbackMessage;
  }

  private extractError(error: unknown): unknown {
    // Allow custom overrides of extracting function
    if (!this.config.extractor) {
      return this.defaultExtractor(error);
    }
    const defaultExtractor: (errorCandidate: unknown) => unknown = this.defaultExtractor.bind(this);
    return this.config.extractor?.(error, defaultExtractor);
  }

  private defaultExtractor(errorCandidate: unknown): unknown {
    let error: unknown = this.tryToUnwrapZonejsError(errorCandidate);

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      return this.extractHttpModuleError(error);
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || this.isErrorOrErrorLikeObject(error)) {
      return error;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }

  private tryToUnwrapZonejsError(error: unknown): unknown | Error {
    // TODO: once Angular14 is the minimum requirement ERROR_ORIGINAL_ERROR and
    //  getOriginalError from error.ts can be used directly.
    //eslint-disable-next-line max-len
    return error && (error as { ngOriginalError: Error }).ngOriginalError
      ? (error as { ngOriginalError: Error }).ngOriginalError
      : error;
  }

  private extractHttpModuleError(error: HttpErrorResponse): string | Error {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (this.isErrorOrErrorLikeObject(error.error)) {
      return error.error;
    }

    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent && error.error.message) {
      return error.error.message;
    }

    if (this.isErrorResponseBody(error.error)) {
      return new ServerResponseError(error.error.message, error.error.code);
    }

    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    // If we don't have any detailed information, fallback to the request message itself.
    return error.message;
  }

  private isErrorOrErrorLikeObject(value: unknown): value is Error {
    if (value instanceof Error) {
      return true;
    }

    if (value === null || typeof value !== 'object') {
      return false;
    }

    const candidate: ErrorCandidate = value as ErrorCandidate;

    return (
      typeof candidate.name === 'string' &&
      typeof candidate.message === 'string' &&
      (undefined === candidate.stack || typeof candidate.stack === 'string')
    );
  }

  private isErrorResponseBody(value: unknown): value is ServerResponseErrorCandidate {
    const candidate: ServerResponseErrorCandidate = value as ServerResponseErrorCandidate;

    //noinspection SuspiciousTypeOfGuard
    return typeof candidate.code === 'number' && typeof candidate.message === 'string';
  }
}
