import { NgModule } from '@angular/core';
import { KebabCasePipe } from './kebab-case.pipe';

/**
 * @deprecated use standalone instead
 */
@NgModule({
  exports: [KebabCasePipe],
  imports: [KebabCasePipe],
})
export class KebabCaseModule {}
