import { SelectionChange as BaseSelectionChange, SelectionModel } from '@angular/cdk/collections';
import { formatISO, isDate } from 'date-fns';
import { Subject } from 'rxjs';

export type UniqueIdentifierPredicate<T> = (element: T) => string | number;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function isKeyOf<T>(element: T, key: any): key is keyof T {
  return typeof element === 'object' && Object.prototype.hasOwnProperty.call(element, key);
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function hasToString(element: any): element is { toString(): string } {
  return element.hasOwnProperty('toString');
}

/**
 * When the second parameter of the {@see TtSelectionModel.predicate} isn't set
 * it will use this function a default predicate.
 *
 * This function will work with most of our classes by either checking for the {@see JsonLd.getIri}
 * method or checking if the object have an id property.
 */
export function defaultPredicate<T extends object | string | number | Date>(
  element: T,
): string | number {
  if (typeof element === 'string' || typeof element === 'number') {
    return element;
  }
  if (typeof element === 'object' && 'getIri' in element && typeof element.getIri === 'function') {
    return element.getIri() as string;
  }
  if (typeof element === 'object' && 'id' in element) {
    return element['id'] as string | number;
  }

  if (isDate(element)) {
    return formatISO(element as Date);
  }

  if (hasToString(element)) {
    return element.toString();
  }

  throw Error('add a string or predicate function to determine the unique id of the elements');
}

export function predicateFromString<T>(key: string) {
  return (element: T): string | number => {
    if (typeof element === 'string' || typeof element === 'number') {
      return element;
    }
    if (isKeyOf(element, key)) {
      if (typeof element[key] === 'string' || typeof element[key] === 'number') {
        return element[key] as string | number;
      }
    }

    if (hasToString(element)) {
      return element.toString();
    }

    throw Error(
      "Given predicate key doesn't return a string or number, create a predicate function instead",
    );
  };
}

export interface SelectionChangeOptions {
  /**
   * If true no change event will be triggered for this change. Use this carefully
   * because it can mess up the angular change detection
   * if not careful.
   */
  emitEvent: boolean;

  keepEmitEventCache?: boolean;
}

export interface SelectionChange<T extends object | string | number | Date>
  extends BaseSelectionChange<T> {
  /**
   * Model that dispatched the event.
   */
  source: TtSelectionModel<T>;

  /**
   * Complete list of all selected Elements in this selection
   */
  selected: T[];

  /**
   * Elements that were added to this selection
   */
  added: T[];

  /**
   * Elements that were removed from this selection.
   */
  removed: T[];

  /**
   * Message about what action caused the change emit. Useful for debugging
   */
  reason: string;
}

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export class TtSelectionModel<T extends object | string | number | Date> extends SelectionModel<T> {
  /**
   * Currently-selected element.
   */
  public readonly selection: Map<string | number, T>;

  /**
   * Event emitter emitting if a selection has changed.
   */
  //eslint-disable-next-line max-len
  public override readonly changed: Subject<SelectionChange<T> | BaseSelectionChange<T>> =
    new Subject<SelectionChange<T> | BaseSelectionChange<T>>();

  /**
   * Selected Elements.
   */
  public override get selected(): T[] {
    if (!this.selectedCache) {
      this.selectedCache = [...this.selection.values()];
    }

    return this.selectedCache;
  }

  /**
   * Returns the number of selected items.
   */
  public get size(): number {
    return this.selection.size;
  }

  /**
   * Keeps track of the deselected elements that haven't been emitted by the change event.
   */
  private deselectedToEmit: T[] = [];

  /**
   * Keeps track of the selected elements that haven't been emitted by the change event.
   */
  private selectedToEmit: T[] = [];

  /**
   * Unique identifier to identify the element.
   */
  private readonly predicate: UniqueIdentifierPredicate<T>;

  /**
   * if true multiple elements may be selected otherwise only one may be selected
   */
  private readonly multiple: boolean;

  /**
   * Cache for the array value of the selected items.
   */
  private selectedCache!: T[] | null;

  constructor(
    multiple: boolean = false,
    predicate: string | UniqueIdentifierPredicate<T> = defaultPredicate,
    initiallySelectedValues?: T[],
    emitChanges: boolean = true,
  ) {
    super(multiple, undefined, emitChanges);
    this.predicate = typeof predicate === 'string' ? predicateFromString(predicate) : predicate;
    this.multiple = multiple ?? false;
    if (initiallySelectedValues) {
      //eslint-disable-next-line max-len
      const toSelect: [string | number, T][] = (initiallySelectedValues ?? []).map((item) => [
        this.predicate(item),
        item,
      ]);
      this.selection = new Map<string | number, T>(this.multiple ? toSelect : toSelect.slice(0, 1));
    } else {
      this.selection = new Map<string | number, T>([]);
    }
  }

  /**
   * @inheritDoc
   * @param elements The elements to add to this selection.
   */
  public override select(...elements: T[]): boolean | void;

  /**
   * @inheritDoc
   * @param elements The elements to add to this selection.
   * @param options Options object {@see SelectionChangeOptions}
   */
  public override select(options: SelectionChangeOptions, ...elements: T[]): boolean | void;

  public override select(
    options: SelectionChangeOptions | T = { emitEvent: true },
    ...elements: T[]
  ): boolean | void {
    if (typeof options === 'object' && !('emitEvent' in options)) {
      (elements ?? []).push(options as T);
      options = { emitEvent: true };
    }
    if (Array.isArray(elements)) {
      this.verifyValueAssignment(elements);
      for (const element of elements) {
        this.markSelected(element);
      }
      this.emitChangeEvent(options as SelectionChangeOptions, 'Added elements to selection');
      return this.hasQueuedChanges();
    }

    this.markSelected(elements);
    this.emitChangeEvent(options as SelectionChangeOptions, 'Added element to selection');
    return this.hasQueuedChanges();
  }

  /**
   * @inheritDoc
   * @param elements The Elements to remove
   * @param options Options object {@see SelectionChangeOptions}
   */
  public override deselect(options: SelectionChangeOptions, ...elements: T[]): boolean;

  public override deselect(...elements: T[]): boolean;

  public override deselect(
    options: SelectionChangeOptions | T = { emitEvent: true },
    ...elements: T[]
  ): boolean {
    if (!options.hasOwnProperty('emitEvent')) {
      (elements ?? []).push(options as T);
      options = { emitEvent: true };
    }

    if (Array.isArray(elements)) {
      this.verifyValueAssignment(elements);
      for (const element of elements) {
        this.unmarkSelected(element);
      }
      this.emitChangeEvent(options as SelectionChangeOptions, 'Removed elements from selection');
      return this.hasQueuedChanges();
    }

    this.unmarkSelected(elements);
    this.emitChangeEvent(options as SelectionChangeOptions, 'Removed element from selection');
    return this.hasQueuedChanges();
  }

  public override setSelection(options: SelectionChangeOptions, ...elements: T[]): boolean;

  public override setSelection(...elements: T[]): boolean;

  /**
   * @inheritDoc
   * @param elements The new selected values
   * @param options Options object {@see SelectionChangeOptions}
   */
  public override setSelection(
    options: SelectionChangeOptions | T = { emitEvent: true },
    ...elements: T[]
  ): boolean | void {
    if (!options.hasOwnProperty('emitEvent')) {
      (elements ?? []).push(options as T);
      options = { emitEvent: true };
    }
    this.unmarkAll({ emitEvent: false });
    if (Array.isArray(elements)) {
      this.verifyValueAssignment(elements);
      for (const element of elements) {
        this.markSelected(element);
      }
      this.emitChangeEvent(options as SelectionChangeOptions, 'Added elements to selection');
      return this.hasQueuedChanges();
    }

    this.markSelected(elements);
    this.emitChangeEvent(options as SelectionChangeOptions, 'Added element to selection');
    return this.hasQueuedChanges();
  }

  public override toggle(elements: T | T[]): boolean;

  /**
   * @inheritDoc
   * @param elements The value to toggle
   * @param options Options object {@see SelectionChangeOptions}
   */
  public override toggle(
    options: SelectionChangeOptions | T | T[] = { emitEvent: true },
    ...elements: T[]
  ): boolean {
    elements = Array.isArray(elements) ? elements : [elements];
    if (!options.hasOwnProperty('emitEvent')) {
      elements = ((elements ?? []) as T[]).concat(
        (Array.isArray(options) ? options : [options]) as T[],
      );
      options = { emitEvent: true };
    }
    if (Array.isArray(elements)) {
      for (let element of elements) {
        if (this.isSelected(element)) {
          this.unmarkSelected(element);
          continue;
        }
        this.markSelected(element);
      }
      this.emitChangeEvent(options as SelectionChangeOptions, 'toggled elements from selection');
      return this.hasQueuedChanges();
    }

    if (this.isSelected(elements)) {
      this.deselect(options as SelectionChangeOptions, elements);
      return this.hasQueuedChanges();
    }

    this.select(options as SelectionChangeOptions, elements);
    return this.hasQueuedChanges();
  }

  /**
   * @inheritDoc
   *
   * @param flushEvent Whether to flush the changes in an event.
   *   If false, the changes to the selection will be flushed along with the next event.
   */
  public override clear(flushEvent?: boolean | SelectionChangeOptions): boolean | void;
  public override clear(
    flushEvent: boolean | SelectionChangeOptions = true,
    options: SelectionChangeOptions = { emitEvent: true },
  ): boolean | void {
    if (typeof flushEvent !== 'boolean') {
      options = flushEvent as SelectionChangeOptions;
    }

    this.unmarkAll(options);
  }

  /**
   * @inheritDoc
   */
  public override isSelected(element: T): boolean {
    if (this.selection.size === 0) {
      return false;
    }

    return this.selection.has(this.predicate(element));
  }

  /**
   * @inheritDoc
   */
  public override isEmpty(): boolean {
    return this.selection.size === 0;
  }

  /**
   * @inheritDoc
   */
  public override hasValue(): boolean {
    return !this.isEmpty();
  }

  /**
   * Sorts the selected values based on a predicate function.
   */
  public override sort(predicate?: (a: T, b: T) => number): void {
    if (this.multiple && this.selected) {
      (this.selectedCache ?? []).sort(predicate);
    }
  }

  /**
   * @inheritDoc
   */
  public override isMultipleSelection(): boolean {
    return this.multiple;
  }

  public triggerChangeEvent(options: Omit<SelectionChangeOptions, 'emitEvent'>, reason: string) {
    this.emitChangeEvent({ emitEvent: true, ...options }, reason);
  }

  /**
   * Emits a change event and clears the records of selected and deselected values.
   */
  private emitChangeEvent(options: SelectionChangeOptions, reason: string): void {
    // Clear the selected values so they can be re-cached.
    if (!options.keepEmitEventCache) {
      this.selectedCache = null;
    }

    if (options.emitEvent && (this.selectedToEmit.length || this.deselectedToEmit.length)) {
      this.changed.next({
        source: this,
        selected: this.selected,
        added: this.selectedToEmit,
        removed: this.deselectedToEmit,
        reason: reason,
      });
    }

    if (!options.keepEmitEventCache) {
      this.selectedToEmit = [];
      this.deselectedToEmit = [];
    }
  }

  /**
   * Add a new element to this selection.
   * @param element The element to add to this selection.
   */
  private markSelected(element: T): boolean {
    if (!this.isSelected(element)) {
      if (!this.multiple) {
        this.unmarkAll({ emitEvent: false });
      }
      this.selection.set(this.predicate(element), element);
      this.selectedToEmit.push(element);
      return true;
    }
    return false;
  }

  /**
   * Removes the given element from this selection.
   * @param element The Element to remove
   */
  private unmarkSelected(element: T): void {
    if (this.isSelected(element)) {
      this.selection.delete(this.predicate(element));
      this.deselectedToEmit.push(element);
    }
  }

  /**
   * Clears out the selected values.
   */
  private unmarkAll(options: SelectionChangeOptions = { emitEvent: true }): void {
    if (this.hasValue()) {
      this.selection.forEach((value) => this.unmarkSelected(value));
      this.emitChangeEvent(options, 'Removed all elements from this selection');
    }
  }

  /**
   * Verifies the value assignment and throws an error if the specified value array is
   * including multiple values while the selection model is not supporting multiple values.
   */
  private verifyValueAssignment(values: T[]): void {
    if (values.length > 1 && !this.multiple) {
      throw Error('Cannot pass multiple values into SelectionModel with single-value mode.');
    }
  }

  private hasQueuedChanges(): boolean {
    return !!(this.deselectedToEmit.length || this.selectedToEmit.length);
  }
}
