import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';

/**
 * @deprecated use standalone instead
 */
@NgModule({
  imports: [SafeHtmlPipe],
  exports: [SafeHtmlPipe],
})
export class SafeHtmlModule {}
