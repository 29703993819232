import { NgModule } from '@angular/core';
import { CapitalizePipe } from './capitalize.pipe';

/**
 * @deprecated use CapitalizePipe as standalone component
 * will be removed in v14
 */
@NgModule({
  exports: [CapitalizePipe],
  imports: [CapitalizePipe],
})
export class CapitalizeModule {}
