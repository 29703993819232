import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthConfig } from '../auth.config';
import { OAuthService } from '../shared/oauth/oauth.service';

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  private readonly storage = inject(Storage);
  private readonly oAuthService = inject(OAuthService);
  private readonly config = inject(AuthConfig);

  /**
   * @inheritDoc
   */
  public intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    // Don't add the access token to the /token request
    if (request.url.includes('/token') || request.method === 'JSONP') {
      return next.handle(request.clone());
    }

    // Check if URL is on the whitelist: don't add the access token to the request
    if (
      (this.config.whitelist ?? []).length &&
      new RegExp((this.config.whitelist ?? []).join('|')).test(request.url)
    ) {
      return next.handle(request.clone());
    }

    return this.requestWithToken(request, next).pipe(
      catchError((error: unknown) => {
        // only display errors when there is no 401 error
        if (!(error instanceof HttpErrorResponse) || error.status !== 401) {
          return throwError(() => error);
        }

        // if we receive a 401 refresh the accessToken or if necessary
        // redirect the user to the login page
        return from(this.oAuthService.refreshAccessToken()).pipe(
          switchMap(() => this.requestWithToken(request, next)),
        );
      }),
    );
  }

  /**
   * Get the accessToken from the localstorage and do the request
   */
  private requestWithToken(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(this.storage.create().then(() => this.storage.get('accessToken'))).pipe(
      map((accessToken) => {
        return !accessToken
          ? request.clone()
          : request.clone({
              headers: request.headers.set('Authorization', 'Bearer ' + accessToken),
            });
      }),
      mergeMap((newRequest) => next.handle(newRequest)),
    );
  }
}
