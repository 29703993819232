import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe, DatePipeConfig } from '@angular/common';
import { LOCALE_ID, Pipe, PipeTransform, inject } from '@angular/core';
import { DateRange } from '@angular/material/datepicker';
import { isDate as fnsIsDate, isSameDay, parse } from 'date-fns';
import { TsRangeInterface } from './ts-range.interface';

/**
 * @deprecated move to tt-range packages. import it from `@techniek-team/tt-range` instead.
 * Will be removed after next major release v16.
 */
@Pipe({
  name: 'ttDate',
  standalone: true,
})
export class TtDateRangePipe implements PipeTransform {
  private readonly datePipeConfig = inject<DatePipeConfig>(DATE_PIPE_DEFAULT_OPTIONS, {
    optional: true,
  });

  public transform(
    value: TsRangeInterface<Date | string> | DateRange<Date> | Date,
    options?: {
      format?: string;
      divider?: string;
      shorten?: boolean;
      timezone?: string;
      locale?: string;
    },
  ): string;
  public transform(
    value: TsRangeInterface<Date | string> | DateRange<Date> | Date,
    options: string,
  ): string;

  /**
   * @deprecated use an options object as second parameter
   */
  public transform(
    value: TsRangeInterface<Date | string> | DateRange<Date> | Date | null,
    format?: string,
    divider?: string,
    shorten?: boolean,
    timezone?: string,
    locale?: string,
  ): string | null;
  /**
   * @deprecated use an options object as second parameter
   */
  public transform(
    value: null | undefined,
    format?: string,
    divider?: string,
    shorten?: boolean,
    timezone?: string,
    locale?: string,
  ): null;
  public transform(
    value: null | undefined,
    options?: {
      format?: string;
      divider?: string;
      shorten?: boolean;
      timezone?: string;
      locale?: string;
    },
  ): null;
  //eslint-disable-next-line complexity,max-lines-per-function
  public transform(
    value: TsRangeInterface<Date | string> | string | DateRange<Date> | Date | null | undefined,
    options?:
      | string
      | {
          format?: string;
          divider?: string;
          shorten?: boolean;
          timezone?: string;
          locale?: string;
        },
    divider?: string,
    shorten?: boolean,
    timezone?: string,
    locale?: string,
  ): string | null {
    if (typeof options === 'string' || divider || shorten || timezone || locale) {
      options = {
        format: options as string | undefined,
        divider: divider,
        shorten: shorten ?? false,
        timezone: timezone,
        locale: locale,
      };
    } else {
      options = { divider: ' - ', shorten: true, ...options };
    }

    const datePipe: DatePipe = new DatePipe(
      options.locale ?? 'nl-NL',
      this.datePipeConfig?.timezone,
      this.datePipeConfig,
    );
    const formatString: string = options.format ?? (this.datePipeConfig?.dateFormat as string);

    if (!value) {
      return null;
    }

    if (fnsIsDate(value) || typeof value === 'string') {
      return datePipe.transform(value, options.format, options.timezone, options.locale);
    }

    if (!this.isRange(value)) {
      return value;
    }

    return this.transformDateRange(value, options, datePipe, formatString);
  }

  private transformDateRange(
    value: TsRangeInterface<Date | string> | DateRange<Date>,
    options: {
      format?: string;
      divider?: string;
      shorten?: boolean;
      timezone?: string;
      locale?: string;
    },
    datePipe: DatePipe,
    formatString: string,
  ): string {
    const startString: string =
      datePipe.transform(value.start, options.format, options.timezone, options.locale) ?? '';
    const startDate: Date = this.getDate(value.start as string | Date, formatString);

    const endString: string =
      datePipe.transform(value.end, options.format, options.timezone, options.locale) ?? '';
    const endDate: Date = this.getDate(value.end as string | Date, formatString);
    // check if format doesn't contain any smaller than a day
    if (options.shorten && formatString.match('[^hHmsS]*') && isSameDay(startDate, endDate)) {
      return `${startString}`;
    }

    return `${startString}${options.divider}${endString}`;
  }

  private isRange(
    value: TsRangeInterface<Date | string> | DateRange<Date>,
  ): value is TsRangeInterface<Date | string> | DateRange<Date> {
    if (!('start' in value && 'end' in value)) {
      return false;
    }

    if (!(typeof value.start === 'string' || fnsIsDate(value.start))) {
      return false;
    }

    return typeof value.end === 'string' || fnsIsDate(value.end);
  }

  private getDate(value: string | Date, format: string): Date {
    return fnsIsDate(value) ? (value as Date) : parse(value, format, new Date());
  }
}
