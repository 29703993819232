import { EnvironmentProviders, ErrorHandler, Provider } from '@angular/core';
import { Integration } from '@sentry/types';
import { UserInterface } from '@techniek-team/oauth';
import { SentryErrorHandler } from './sentry-error-handler.service';
import { SentryErrorHandlerMock } from './sentry-error-handler.service.mock';
import { SentryWebConfig } from './sentry-web.config';

export function provideSentryTesting<T extends UserInterface>(
  options: SentryWebConfig,
  ...features: (
    | (Provider | EnvironmentProviders)[]
    | {
        integrations: Integration[];
        providers: (Provider | EnvironmentProviders)[];
      }
  )[]
) {
  options = { ...new SentryWebConfig(), ...options };

  const featureProviders = [];
  for (let feature of features) {
    if (Array.isArray(feature)) {
      featureProviders.push(...feature);
      continue;
    }
    featureProviders.push(...feature.providers);
  }

  return [
    ...featureProviders,
    { provide: SentryWebConfig, useValue: options },
    { provide: SentryErrorHandler, useClass: SentryErrorHandlerMock },
    { provide: ErrorHandler, useExisting: SentryErrorHandler },
  ];
}
