import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector, inject } from '@angular/core';
import { captureException, getCurrentScope, showReportDialog } from '@sentry/angular';
import { UserServiceInterface } from '@techniek-team/common';
import { Subject } from 'rxjs';
import { distinctUntilKeyChanged, filter } from 'rxjs/operators';
import { SentryEnvironment, SentryWebConfig } from './sentry-web.config';
import { ServerResponseError } from './server-response.error';
import { UserInterface } from './user-service.interface';

// That's the `global.Zone` exposed when the `zone.js` package is used.
//eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
declare const Zone: any;

interface DialogStream {
  eventId: string;
  event: unknown;
  fingerprint: string;
}

interface ErrorCandidate {
  name?: unknown;
  message?: unknown;
  stack?: unknown;
}

interface ServerResponseErrorCandidate {
  code: number;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  private readonly injector = inject(Injector);

  private readonly config = inject(SentryWebConfig);

  /**
   * Subject to limit the amount of sentry dialogs one sees.
   */
  private dialogStream$: Subject<DialogStream> = new Subject<DialogStream>();

  /**
   * Warning: since this error handler is provided before all other modules;
   * injecting services will cause multiple instances across the application.
   *
   * Use the `injector` in handleError to fetch the services lazily instead.
   */
  constructor() {
    this.dialogStream$
      .pipe(
        distinctUntilKeyChanged('fingerprint'),
        filter((event: DialogStream) => {
          if (!(this.config.environment === SentryEnvironment.PRODUCTION)) {
            // Collapsed console group instead of a list of errors for legibility.
            console.groupCollapsed('Sentry -> handleError');
            // eslint-disable-next-line no-console
            console.log(event.event);
            console.groupEnd();
          }
          //@ts-ignore
          const existing: HtmlCollectionOf<HTMLDivElement> = document.getElementsByClassName(
            'sentry-error-embed-wrapper',
          );

          return !existing || existing?.length === 0;
        }),
      )
      .subscribe((event: DialogStream) => this.showErrorDialog(event.eventId));
  }

  /**
   * Method called for every value captured through the {@see ErrorHandler}.
   * It return it on his turn through the {@see self.handle} method show a dialog
   * it {@see SentryWebConfig.showDialog} is true.
   */
  public handleError(error: unknown): void {
    this.handle(error, this.config.showDialog ?? false);
  }

  /**
   * This method can be called to manually send a silent Exception to sentry (when
   * not in local environment) and the console (when not in production)
   */
  public captureError(error: unknown): Promise<string | void> {
    return this.handle(error, false);
  }

  /**
   * Cqn be used in for example a toast service to retrieve the message that need
   * to be shown to the user
   */
  //eslint-disable-next-line complexity
  public extractMessage(
    e: unknown,
    fallbackMessage: string = 'Oeps, er is iets misgegaan.',
  ): string {
    const mapping: Record<string, string> = this.config.errorMessageMapping ?? {};
    const error: unknown = this.extractError(e);

    if (error instanceof ServerResponseError || this.isErrorResponseBody(error)) {
      if (mapping[error.message]) {
        return mapping[error.message];
      }
      return error.message;
    }

    if (error instanceof Error) {
      if (mapping[error.message]) {
        return mapping[error.message];
      }
      return fallbackMessage;
    }

    if (typeof error === 'string') {
      if (mapping[error]) {
        return mapping[error];
      }
      return fallbackMessage;
    }

    if (error && typeof error === 'object' && 'toString' in error) {
      if (mapping[error.toString()]) {
        return mapping[error.toString()];
      }
      return fallbackMessage;
    }

    return fallbackMessage;
  }

  /**
   * Call the method to manually send a message to Sentry.
   *
   * @deprecated
   */
  public async captureMessage(error: string): Promise<void> {
    if (!(this.config.environment === SentryEnvironment.PRODUCTION)) {
      //eslint-disable-next-line no-console
      console.warn(
        "WARNING: you're still using the `SentryErrorHandler.captureMessage. " +
          'This method is deprecated. and will be removed in the future. ' +
          'Replace it with `SentryErrorHandler.captureError` instead!',
      );
      //eslint-disable-next-line no-console
      console.trace();
    }

    await this.handle(new Error('Backwards compatible error captureMessage: ' + error), false);
  }

  /**
   * When an error is captured it will print the error in the console. Except
   * when in production mode. It will also send the error to sentry. Except in
   * local environment (which you can override with the
   * {@see SentryWebConfig.sendLocalErrorsToSentry}.
   *
   * It will also show a dialog to ask the user for a description of the error.
   * Which will be send to Sentry as well. The dialog can be disabled with
   * {@see SentryWebConfig.showDialog }.
   */
  private async handle(error: unknown, showDialog: boolean): Promise<string | void> {
    const extractedError: unknown = this.extractError(error) || 'Handled unknown error';

    if (!(this.config.environment === SentryEnvironment.PRODUCTION)) {
      // Console group instead of a list of errors for legibility.
      console.group('Sentry -> captureError');
      console.error(extractedError);
      console.groupEnd();
    }

    if (
      !(this.config.environment === SentryEnvironment.LOCAL) ||
      this.config.sendLocalErrorsToSentry
    ) {
      await this.setUser();

      // Capture handled exception and send it to Sentry.
      const eventId: string = this.runOutsideAngular(() => captureException(extractedError));

      if (showDialog) {
        this.dialogStream$.next({
          eventId: eventId,
          event: extractedError,
          fingerprint: this.generateFingerPrint(extractedError),
        });
      }

      return eventId;
    }
  }

  /**
   * Configure the user for the error scope so we can see
   * which user triggered this error in Sentry.
   *
   * We don't have any users yet.
   */
  private async setUser(): Promise<void> {
    if (this.config.userService) {
      const userService: UserServiceInterface<UserInterface> = this.injector.get(
        await this.config.userService,
      );
      const user: UserInterface | undefined = await userService.getUser();

      if (!user) {
        return;
      }

      // We know this works because we've seen the user data in Sentry
      // TODO fix me if you know how to test this callback! :)
      getCurrentScope().setUser({
        id: user.id.toString(),
        username: user.fullName,
        email: user.email,
      });
    }
  }

  /**
   * Show the error dialog for Sentry with the given event id.
   */
  //eslint-disable-next-line complexity
  private showErrorDialog(eventId: string): void {
    showReportDialog({
      eventId: eventId,
      lang: this.config?.dialogOptions?.lang ?? 'nl',
      title: this.config?.dialogOptions?.title ?? 'Oeps! Er is iets misgegaan.',
      subtitle:
        this.config?.dialogOptions?.subtitle ??
        'Er is een melding verstuurd. Als je wilt helpen, ' +
          'vertel ons dan hieronder wat er is gebeurd.',
      subtitle2:
        this.config?.dialogOptions?.subtitle2 ??
        'Vergeet niet om na het melden van de bug de pagina te verversen.',
      labelName: this.config?.dialogOptions?.labelName ?? 'Naam',
      labelEmail: this.config?.dialogOptions?.labelEmail ?? 'E-mailadres',
      labelComments: this.config?.dialogOptions?.labelComments ?? 'Wat is het probleem?',
      labelClose: this.config?.dialogOptions?.labelClose ?? 'Sluiten',
      labelSubmit: this.config?.dialogOptions?.labelSubmit ?? 'Verstuur bug',
      errorFormEntry:
        this.config?.dialogOptions?.errorFormEntry ??
        'Sommige velden zijn onjuist ingevuld. Corrigeer de fouten en probeer het opnieuw.',
      errorGeneric:
        this.config?.dialogOptions?.errorGeneric ??
        'Er is een onbekende fout opgetreden bij het indienen van de bug. Probeer het opnieuw.',
      successMessage:
        this.config?.dialogOptions?.successMessage ??
        'Bedankt voor de feedback. Vergeet niet de pagina te verversen om andere bugs te voorkomen.',
    });
  }

  /**
   * Direct copy past of the @sentry/angular ErrorHandler implementation. Check there implementation on:
   * { @see https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts#L60}
   *
   * Used to pull a desired value that will be used to capture an event out of the raw value captured by ErrorHandler.
   */
  private extractError(error: unknown): unknown {
    // Allow custom overrides of extracting function
    if (!this.config.extractor) {
      return this.defaultExtractor(error);
    }
    const defaultExtractor: (errorCandidate: unknown) => unknown = this.defaultExtractor.bind(this);
    return this.config.extractor?.(error, defaultExtractor);
  }

  //noinspection JSMethodCanBeStatic
  /**
   * Direct copy past of the @sentry/angular ErrorHandler implementation. Check there implementation on:
   * { @see https://github.com/getsentry/sentry-javascript/blob/master/packages/angular/src/errorhandler.ts#L73}
   *
   * Default implementation of error extraction that handles default error wrapping,
   * HTTP responses, ErrorEvent and few other known cases.
   */
  private defaultExtractor(errorCandidate: unknown): unknown {
    let error: unknown = this.tryToUnwrapZonejsError(errorCandidate);

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    if (error instanceof HttpErrorResponse) {
      return this.extractHttpModuleError(error);
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || this.isErrorOrErrorLikeObject(error)) {
      return error;
    }

    // Nothing was extracted, fallback to default error message.
    return null;
  }

  private tryToUnwrapZonejsError(error: unknown): unknown | Error {
    // TODO: once Angular14 is the minimum requirement ERROR_ORIGINAL_ERROR and
    //  getOriginalError from error.ts can be used directly.
    //eslint-disable-next-line max-len
    return error && (error as { ngOriginalError: Error }).ngOriginalError
      ? (error as { ngOriginalError: Error }).ngOriginalError
      : error;
  }

  private extractHttpModuleError(error: HttpErrorResponse): string | Error {
    // The `error` property of http exception can be either an `Error` object, which we can use directly...
    if (this.isErrorOrErrorLikeObject(error.error)) {
      return error.error;
    }

    // ... or an`ErrorEvent`, which can provide us with the message but no stack...
    if (error.error instanceof ErrorEvent && error.error.message) {
      return error.error.message;
    }

    if (this.isErrorResponseBody(error.error)) {
      return new ServerResponseError(error.error.message, error.error.code);
    }

    // ...or the request body itself, which we can use as a message instead.
    if (typeof error.error === 'string') {
      return `Server returned code ${error.status} with body "${error.error}"`;
    }

    // If we don't have any detailed information, fallback to the request message itself.
    return error.message;
  }

  private isErrorOrErrorLikeObject(value: unknown): value is Error {
    if (value instanceof Error) {
      return true;
    }

    if (value === null || typeof value !== 'object') {
      return false;
    }

    const candidate: ErrorCandidate = value as ErrorCandidate;

    return (
      typeof candidate.name === 'string' &&
      typeof candidate.message === 'string' &&
      (undefined === candidate.stack || typeof candidate.stack === 'string')
    );
  }

  private isErrorResponseBody(value: unknown): value is ServerResponseErrorCandidate {
    const candidate: ServerResponseErrorCandidate = value as ServerResponseErrorCandidate;

    //noinspection SuspiciousTypeOfGuard
    return typeof candidate.code === 'number' && typeof candidate.message === 'string';
  }

  //noinspection JSMethodCanBeStatic
  private generateFingerPrint(error: unknown): string {
    let fingerprint: string;

    try {
      fingerprint = JSON.stringify(error);
    } catch (circular) {
      /* istanbul ignore next */
      fingerprint = 'NOCIRCULAR';
    }
    if (error instanceof Error) {
      fingerprint = fingerprint + error?.stack + error?.message;
    }

    return SentryErrorHandler.hashFnv32a(fingerprint, true) as string;
  }

  /**
   * Calculate a 32 bit FNV-1a hash
   * Found here: https://gist.github.com/vaiorabbit/5657561
   * Ref.: http://isthe.com/chongo/tech/comp/fnv/
   *
   * @param {string} input the input value
   * @param {boolean} [asString=false] set to true to return the hash value as
   *     8-digit hex string instead of an integer
   * @param {number} [seed] optionally pass the hash of the previous chunk
   * @returns {number | string}
   */
  private static hashFnv32a(input: string, asString: boolean, seed?: number): string | number {
    /*jshint bitwise:false */
    let hashValue: number = seed === undefined ? 0x811c9dc5 : seed;
    let stringLength: number = input.length;

    for (let i: number = 0; i < stringLength; i++) {
      //eslint-disable-next-line no-bitwise
      hashValue ^= input.charCodeAt(i);
      //prettier-ignore
      //eslint-disable-next-line no-bitwise
      hashValue += (hashValue << 1) + (hashValue << 4) + (hashValue << 7) + (hashValue << 8) + (hashValue << 24);
    }
    if (asString) {
      // Convert to 8 digit hex string
      //eslint-disable-next-line no-bitwise
      return ('0000000' + (hashValue >>> 0).toString(16)).slice(-8);
    }
    //eslint-disable-next-line no-bitwise
    /* istanbul ignore next */
    //eslint-disable-next-line
    return hashValue >>> 0;
  }

  /**
   * The function that does the same job as `NgZone.runOutsideAngular`.
   */
  public runOutsideAngular<T>(callback: () => T): T {
    // There're 2 types of Angular applications:
    // 1) zone-full (by default)
    // 2) zone-less
    // The developer can avoid importing the `zone.js` package and tells Angular that
    // he is responsible for running the change detection by himself. This is done by
    // "nooping" the zone through `CompilerOptions` when bootstrapping the root module.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const isNgZoneEnabled: boolean = typeof Zone !== 'undefined' && !!Zone.current;

    // The `Zone.root.run` basically will run the `callback` in the most parent zone.
    // Any asynchronous API used inside the `callback` won't catch Angular's zone
    // since `Zone.current` will reference `Zone.root`.
    // The Angular's zone is forked from the `Zone.root`. In this case, `zone.js` won't
    // trigger change detection, and `ApplicationRef.tick()` will not be run.
    // Caretaker note: we're using `Zone.root` except `NgZone.runOutsideAngular` since this
    // will require injecting the `NgZone` facade. That will create a breaking change for
    // projects already using the `@sentry/angular`.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    return isNgZoneEnabled ? Zone.root.run(callback) : callback();
  }
}
